import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { Component, useEffect, useState } from "react";

import { Elements, PaymentElement } from "@stripe/react-stripe-js";
import getStripe from "layouts/stripe-container";
import {
  Divider,
  Grid,
  Typography,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import CheckoutForm from "./CheckoutForm";
import PaymentForm from "./PaymentForm";
import { useParams, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getPlan } from "actions";
import { getArtById } from "actions";
import { toast } from "react-toastify";
import { HOST_URL } from "constants";
import { ASSETS_URL } from "constants";

const themeWidth = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

function ItemPurchase() {
  let [piece, setPiece] = useState({
    imageUrl: "",
    title: "",
    credits: "Siyavush Mammad",
    description: "",
    categories: [],
    likesCount: 0,
    starsCount: 0,
    commentsCount: 0,
    viewsCount: 0,
    downloadsCount: 0,
    liked: false,
    starred: false,
    lowRes: {
      width: 512,
      height: 512,
    },
    highRes: {
      width: 512,
      height: 512,
    },
  });

  const sm = useMediaQuery(themeWidth.breakpoints.down("sm"));
  const xs = useMediaQuery(themeWidth.breakpoints.down("xs"));
  const md = useMediaQuery(themeWidth.breakpoints.down("md"));
  const lg = useMediaQuery(themeWidth.breakpoints.down("lg"));
  const { id } = useParams();

  let bearerToken = useSelector((state) => state.navigationReducer.bearerToken);

  useEffect(() => {
    getArtById(id, bearerToken)
      .then((response) => {
        console.log(response.data);

        let entity = response.data;
        let data = {
          imageUrl: `${ASSETS_URL}/${entity.imageCompressedLocation}`,
          imageName: entity.imageCompressedLocation,
          title: entity.name,
          credits: "Siyavush Mammad",
          description: entity.description,
          categories: entity.categories,
          likesCount: entity.likes,
          commentsCount: entity.comments,
          viewsCount: entity.views,
          downloadsCount: entity.downloads,
          liked: entity.liked,
          starred: entity.saved,
          lowRes: entity.lowRes,
          highRes: entity.highRes,
          price: entity.price,
        };

        setPiece(data);
      })
      .catch((error) => {
        toast("Something went wrong!");
      });
  }, []);

  return (
    <Grid container style={{ width: "100%", padding: md ? "15px" : 0 }} pt={10}>
      <Grid container style={{ display: "flex", flexDirection: "row" }}>
        <Grid
          item
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <img
            src={piece.imageUrl}
            style={{
              maxWidth: "512px",
              maxHeight: "512px",
              borderRadius: "10px",
            }}
            srcSet={piece.imageUrl}
            alt={""}
            loading="lazy"
          />
        </Grid>
        <Grid
          item
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexDirection: "column",
            textAlign: "left",
          }}
        >
          {piece ? (
            <>
              <Typography variant="h3">{`${piece.title} (${piece.highRes.width} x ${piece.highRes.height} px) - $${piece.price}`}</Typography>
            </>
          ) : (
            <>
              <Typography variant="h3">{`Smaiart Art Purchase`}</Typography>
              <Typography variant="overline">{`${1} High Resolution Image`}</Typography>
            </>
          )}

          <Divider variant="middle" style={{ width: "100%" }} />

          <PaymentForm itemId={id} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ItemPurchase;
