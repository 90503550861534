import React, { useState } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  Grid,
  Switch,
  FormControlLabel,
  Alert,
  Stack,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import axios from "axios";
import SortableItem from "./SortableItem";
import { useSelector } from "react-redux";
import { Spinner } from "@material-tailwind/react";
import { addDigitalArt } from "actions";

const AddDigitalArt = ({ isAdmin }) => {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    canvasDescription: "",
    categories: "",
    price: "",
    width: "", // Add width field
    height: "", // Add height field
    isSold: false,
    images: [],
  });
  const [error, setError] = useState("");
  let [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  let bearerToken = useSelector((state) => state.navigationReducer.bearerToken);
  //   if (!isAdmin) {
  //     return <Alert severity="error">403 - Forbidden: You do not have access to this page.</Alert>;
  //   }

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setFormData((prev) => ({
      ...prev,
      images: [...prev.images, ...newFiles],
    }));
  };

  const handleRemoveImage = (index) => {
    setFormData((prev) => {
      const updatedImages = prev.images.filter((_, i) => i !== index);
      return { ...prev, images: updatedImages };
    });
  };

  const handleReorder = (activeIndex, overIndex) => {
    if (activeIndex !== overIndex) {
      setFormData((prev) => ({
        ...prev,
        images: arrayMove(prev.images, activeIndex, overIndex),
      }));
    }
  };

  const sensors = useSensors(useSensor(PointerSensor));

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const { name, description, categories, price, images } = formData;
    const categoryArray = categories.split(",").map((cat) => cat.trim());

    const data = new FormData();
    data.append("name", name);
    data.append("description", description);
    categoryArray.forEach((category) => data.append("categories", category));
    data.append("price", price);
    data.append("type", "AIART");
    data.append("image", images[0]);

    try {
      setIsLoading(false);
      await addDigitalArt(data, bearerToken);
      navigate("/success"); // Redirect on success
    } catch (err) {
      setError("Failed to add the art object.");
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" component="h1" gutterBottom>
        Add Digital Art
      </Typography>
      {error && <Alert severity="error">{error}</Alert>}
      <form onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <TextField
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            fullWidth
            required
          />
          <TextField
            label="Description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            multiline
            rows={4}
            fullWidth
            required
          />

          <TextField
            label="Categories"
            name="categories"
            value={formData.categories}
            onChange={handleChange}
            helperText="Separate categories with commas"
            fullWidth
            required
          />
          {/* Height field */}
          <TextField
            label="Price"
            name="price"
            value={formData.price}
            onChange={handleChange}
            type="number"
            fullWidth
            required
          />

          <Button variant="contained" component="label">
            <p style={{ color: "white" }}>Upload Image</p>
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={handleFileChange}
            />
          </Button>
          {/* Image Preview Section with Drag-and-Drop */}
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={(e) => {
              const { active, over } = e;
              if (over) {
                const activeIndex = formData.images.findIndex(
                  (file) => file.name === active.id,
                );
                const overIndex = formData.images.findIndex(
                  (file) => file.name === over.id,
                );
                handleReorder(activeIndex, overIndex);
              }
            }}
          >
            <SortableContext
              items={formData.images}
              strategy={verticalListSortingStrategy}
            >
              <Grid container spacing={2}>
                {formData.images.map((file, index) => (
                  <SortableItem
                    key={file.name}
                    file={file}
                    index={index}
                    onRemove={() => handleRemoveImage(index)}
                  />
                ))}
              </Grid>
            </SortableContext>
          </DndContext>
          <Button type="submit" variant="contained" color="primary">
            {isLoading ? (
              <svg
                class="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  class="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  stroke-width="4"
                ></circle>
                <path
                  class="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            ) : (
              <p style={{ color: "white" }}>Submit</p>
            )}
          </Button>
        </Stack>
      </form>
    </Container>
  );
};

export default AddDigitalArt;
