import {
  Avatar,
  Button,
  Card,
  Divider,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import { addCommentOfTheArt } from "actions";
import { getCommentsOfTheArt } from "actions";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import React, { Component, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { formatDateTime } from "utils";

function DrawerComments({ id, incrementComments }) {
  let [comments, setComments] = useState([]);
  let [isLoading, setIsLoading] = useState(false);

  let [email, setEmail] = useState("");
  let [fullName, setFullName] = useState("");
  let [comment, setComment] = useState("");

  useEffect(() => {
    setIsLoading(true);
    getCommentsOfTheArt(id, 0, 20)
      .then((response) => {
        let comments = response.data;

        setComments(comments);
      })
      .catch((error) => {
        toast("Something went wrong");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  let postComment = () => {
    addCommentOfTheArt(id, email, fullName, comment)
      .then((response) => {
        setEmail("");
        setFullName("");
        setComment("");
        setComments([response.data, ...comments]);
        incrementComments();
      })
      .catch((error) => {
        toast("Something went wrong!");
      });
  };

  return (
    <MDBox style={{ width: "100%", display: "flex", height: "100%" }}>
      <Grid container style={{ display: "flex", flexDirection: "column" }}>
        <Grid
          item
          container
          style={{
            width: "100%",
            flex: 2.5,
            height: "80%",
            overflowY: "scroll",
          }}
        >
          <MDBox style={{ width: "100%" }}>
            <MDTypography variant="h5" color="black" p={3}>
              {"Comments"}
            </MDTypography>
            <div style={{ height: "100px !important", overflow: "y-axis" }}>
              {comments?.map((comment) => {
                return (
                  <MDBox
                    style={{
                      padding: "20px",

                      paddingTop: "20px",
                      paddingBottom: "20px",
                      margin: "5px",
                      boxShadow: "none",
                    }}
                  >
                    <Grid container spacing={2}>
                      <Grid item>
                        <Avatar
                          alt={comment.fullName}
                          sx={{ width: 31, height: 31 }}
                          src={
                            comment.profilePic
                              ? comment.profilePic
                              : `https://api.dicebear.com/5.x/shapes/png?seed=${comment.fullName}.png`
                          }
                          style={{ marginTop: 5 }}
                        />
                      </Grid>
                      <Grid
                        justifyContent="left"
                        style={{ flexDirection: "column", display: "flex" }}
                        item
                        xs
                        zeroMinWidth
                      >
                        <MDTypography variant="button">
                          {comment.fullName}
                        </MDTypography>
                        <MDTypography variant="caption">
                          {formatDateTime(comment.createdDate)}
                        </MDTypography>
                      </Grid>
                    </Grid>
                    <Grid item mt={1}>
                      <MDTypography
                        variant="button"
                        fontWeight="thin"
                        color="black"
                        pl={6}
                        mb={1}
                      >
                        {comment.comment}
                      </MDTypography>
                    </Grid>
                    <Divider />
                  </MDBox>
                );
              })}
            </div>
          </MDBox>
        </Grid>
        <Grid
          item
          style={{
            display: "flex",
            // position: "absolute",

            flex: 1,
            flexDirection: "column",
            width: "100%",
            bottom: 0,
          }}
          rowGap={2}
          p={2}
        >
          <TextField
            style={{ display: "flex", width: "100%" }}
            label="Email"
            rows={1}
            id="outlined-size-big"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            size="large"
          />
          <TextField
            style={{ display: "flex", width: "100%" }}
            label="Name"
            rows={1}
            value={fullName}
            onChange={(e) => {
              setFullName(e.target.value);
            }}
            id="outlined-size-big"
            size="large"
          />
          <TextField
            style={{ display: "flex", width: "100%" }}
            label="Comment"
            multiline
            rows={4}
            value={comment}
            onChange={(e) => {
              setComment(e.target.value);
            }}
            id="outlined-size-big"
            size="large"
          />
          <Button
            variant="contained"
            onClick={postComment}
            sx={{ color: "white !important" }}
          >
            Post
          </Button>
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default DrawerComments;
