import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { Component, useEffect, useState } from "react";

import { Elements, PaymentElement } from "@stripe/react-stripe-js";
import getStripe from "layouts/stripe-container";
import {
  Divider,
  Grid,
  Typography,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import CheckoutForm from "./CheckoutForm";
import PaymentForm from "./PaymentForm";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getPlan } from "actions";
import { HOST_URL } from "constants";
import { ASSETS_URL } from "constants";

const themeWidth = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

function SubscriptionCheckout() {
  const [plan, setPlan] = useState(undefined);
  const [searchParams, setSearchParams] = useSearchParams();
  let [loading, setLoading] = useState(false);
  let subscriptionPlanName = searchParams.get("plan");
  let subscriptionPlanPricingNumberOfItems = searchParams.get("numberOfItems");
  let bearerToken = useSelector((state) => state.navigationReducer.bearerToken);

  const sm = useMediaQuery(themeWidth.breakpoints.down("sm"));
  const xs = useMediaQuery(themeWidth.breakpoints.down("xs"));
  const md = useMediaQuery(themeWidth.breakpoints.down("md"));
  const lg = useMediaQuery(themeWidth.breakpoints.down("lg"));

  useEffect(() => {
    getPlan(
      bearerToken,
      subscriptionPlanName,
      subscriptionPlanPricingNumberOfItems,
    ).then((response) => {
      setPlan(response.data);
    });
  }, []);

  return (
    <Grid container style={{ width: "100%", padding: md ? "15px" : 0 }} pt={10}>
      <Grid container style={{ display: "flex", flexDirection: "row" }}>
        <Grid
          item
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <img
            src={`${ASSETS_URL}/09781a4f-8b08-4661-98f6-d0943ab9661a.jpg`}
            style={{
              maxWidth: "512px",
              maxHeight: "512px",
              borderRadius: "10px",
            }}
            srcSet={`${ASSETS_URL}/09781a4f-8b08-4661-98f6-d0943ab9661a.jpg`}
            alt={""}
            loading="lazy"
          />
        </Grid>
        <Grid
          item
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexDirection: "column",
            textAlign: "left",
          }}
        >
          {plan ? (
            <>
              <Typography variant="h3">{`Smaiart ${plan.subscriptionPlanName} Subscription - $${plan.price}`}</Typography>
              <Typography variant="overline">{`${
                plan.numberOfItems == -1 ? "Unlimited" : plan.numberOfItems
              } High Resolution Images`}</Typography>
            </>
          ) : (
            <>
              <Typography variant="h3">{`Smaiart Pro Subscription - $10`}</Typography>
              <Typography variant="overline">{`${5} High Resolution Images`}</Typography>
            </>
          )}

          <Divider variant="middle" style={{ width: "100%" }} />

          <PaymentForm />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SubscriptionCheckout;
