/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.3 mug.gltf
*/

import React, { useEffect, useRef, useState } from "react";
import { useGLTF } from "@react-three/drei";
import * as THREE from "three";

import { useSnapshot } from "valtio";
import { useFrame } from "@react-three/fiber";

export function MugModel(props) {
  const { nodes, materials } = useGLTF("/mug.glb");
  const ref = useRef();
  const snap = useSnapshot(props.state);

  let [material, setMaterial] = useState(
    materials["03 magi_azoy_S1819913.001"],
  );

  useEffect(() => {
    var texLoader = new THREE.TextureLoader();
    var newTexture = texLoader.load(props.url);

    flipY(nodes["03_magi_azoy_S1819913001"].geometry);

    // material.flipY = true;
    material.map = newTexture;
  }, [props.url]);

  function flipY(geometry) {
    var uv = geometry.attributes.uv;

    for (var i = 0; i < uv.count; i++) {
      uv.setY(i, 1 - uv.getY(i));
    }

    return geometry;
  }

  useFrame((state) => {
    const t = state.clock.getElapsedTime();
    ref.current.rotation.y = Math.sin(t / 4) / 8;
    ref.current.position.y = (1 + Math.sin(t / 1.5)) / 10;
  });
  // Cursor showing current color
  const [hovered, set] = useState(null);
  useEffect(() => {
    const cursor = `<svg width="64" height="64" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0)"><path fill="rgba(255, 255, 255, 0.5)" d="M29.5 54C43.031 54 54 43.031 54 29.5S43.031 5 29.5 5 5 15.969 5 29.5 15.969 54 29.5 54z" stroke="#000"/><g filter="url(#filter0_d)"><path d="M29.5 47C39.165 47 47 39.165 47 29.5S39.165 12 29.5 12 12 19.835 12 29.5 19.835 47 29.5 47z" fill="${snap.items[hovered]}"/></g><path d="M2 2l11 2.947L4.947 13 2 2z" fill="#000"/><text fill="#000" style="white-space:pre" font-family="Inter var, sans-serif" font-size="10" letter-spacing="-.01em"><tspan x="35" y="63">${hovered}</tspan></text></g><defs><clipPath id="clip0"><path fill="#fff" d="M0 0h64v64H0z"/></clipPath><filter id="filter0_d" x="6" y="8" width="47" height="47" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/><feOffset dy="2"/><feGaussianBlur stdDeviation="3"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/><feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/></filter></defs></svg>`;
    const auto = `<svg width="64" height="64" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="rgba(255, 255, 255, 0.5)" d="M29.5 54C43.031 54 54 43.031 54 29.5S43.031 5 29.5 5 5 15.969 5 29.5 15.969 54 29.5 54z" stroke="#000"/><path d="M2 2l11 2.947L4.947 13 2 2z" fill="#000"/></svg>`;
    document.body.style.cursor = `url('data:image/svg+xml;base64,${btoa(
      hovered ? cursor : auto,
    )}'), auto`;
  }, [hovered]);

  return (
    <group {...props} dispose={null} ref={ref}>
      <mesh
        geometry={nodes.mug.geometry}
        material={materials.Porcelain}
        material-color={props.color ? props.color : "#ffffff"}
      />
      <mesh
        geometry={nodes.hold.geometry}
        material={materials.Porcelain}
        material-color={props.color ? props.color : "#ffffff"}
      />
      <mesh
        geometry={nodes["03_magi_azoy_S1819913001"].geometry}
        material={material}
      />
    </group>
  );
}

useGLTF.preload("/mug.gltf");
