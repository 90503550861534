import { Dialog } from "@headlessui/react";
import { stringify } from "qs";
import { Modal } from "components/Modal";
import MDButton from "components/MDButton";
import "./index.css";
import SelectedDigitalArtModal from "layouts/selected-digital-art-modal";
import { createTheme, useMediaQuery } from "@mui/material";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1024,
      xl: 1536,
    },
  },
});

export const ImageDetailModal = ({ image, isOpen, onClose }) => {
  const {
    createdAtString,
    createdByUserName,
    createdByUserProfileImage,
    createdByUserUid,
    prompt,
    tags,
    modelStyle,
    caption,
  } = image || {};
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const handleCopy = async () => {
    if (!image) {
      return;
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} style={{ overflow: "scroll" }}>
      <Dialog.Panel
        className="relative min-h-[calc(100dvh)] lg:min-h-0 max-h-[calc(100dvh)] overflow-y-auto flex flex-col items-stretch justify-stretch lg:flex-row transform rounded-lg bg-white text-left shadow-xl transition-all lg:max-w-[1224px] custom-scrollbar"
        style={{
          marginTop: lg ? "0" : "100px",
          width: lg ? "100%" : "80%",
        }}
      >
        <div className="flex flex-1 flex-col lg:flex-row lg:min-w-[1024px]">
          {image ? (
            <>
              <SelectedDigitalArtModal onClose={onClose} id={image.id} />
            </>
          ) : null}
        </div>
      </Dialog.Panel>
    </Modal>
  );
};
