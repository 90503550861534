export function nFormatter(num) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(1).replace(rx, "$1") + item.symbol
    : "0";
}

export function formatDateTime(dateTime) {
  const date = new Date(dateTime);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  let hour = date.getHours();
  let minute = date.getMinutes();
  let second = date.getSeconds();

  minute = minute.length == 1 ? "0" + minute : minute;
  hour = hour.length == 1 ? "0" + hour : hour;
  second = second.length == 1 ? "0" + second : second;

  // return `${month}.${day}.${year} ${hour}:${minute}`;
  return date.toDateString();
}

export const downloadImage = (e) => {
  fetch(e.target.href, {
    method: "GET",
    headers: {},
  })
    .then((response) => {
      response.arrayBuffer().then(function (buffer) {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "image.png"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    })
    .catch((err) => {});
};

export function download(content, filename) {
  var contentType = "application/octet-stream";
  var a = document.createElement("a");
  // var blob = new Blob([content], { type: contentType });
  a.href = "data:application/octet-stream;base64," + content;
  a.download = filename;
  a.click();
}

export async function makeRandomSessionId() {
  return makeid(14);
}

function makeid(length) {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validatePassword(password) {
  const re = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
  return re.test(String(password));
}

export function validateUsername(username) {
  const re = /^(?=[a-zA-Z0-9._]{6,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/;
  return re.test(String(username));
}

export function parseDate(date) {
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export function parseTime(time) {
  let hour = time.getHours();
  let minute = time.getMinutes();
  let sec = time.getSeconds();

  return `${hour}h : ${minute}m : ${sec}s`;
}

export function makeHeaders(bearerToken) {
  if (bearerToken === undefined || bearerToken === "") {
    return { "Content-Type": "application/json" };
  }
  return {
    "Content-Type": "application/json",
    Authorization: "Bearer " + bearerToken,
  };
}

export function makeHeadersFormData(bearerToken) {
  if (bearerToken === undefined || bearerToken === "") {
    return { "Content-Type": "multipart/form-data" };
  }
  return {
    "Content-Type": "multipart/form-data",
    Authorization: "Bearer " + bearerToken,
  };
}

export function makeDefaultHeaders() {
  return {
    "Content-Type": "application/json",
  };
}

export const rnd = (max = 256) => Math.random() * max;

export function truncate(str, n) {
  return str.length > n ? str.slice(0, n - 1) + "..." : str;
}

export function mapAuthorities(authorities) {
  if (authorities.includes("ROLE_ADMIN")) {
    return true;
  }
  return false;
}
