import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Divider,
  Grid,
  PaginationItem,
  Typography,
} from "@mui/material";
import { useSelect } from "@react-three/drei";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { Component, useState } from "react";
import { useSelector } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SubscriptionPlans from "layouts/subscription-plans";

function Subscriptions({ children }) {
  let user = useSelector((state) => state.navigationReducer.user);
  let bearerToken = useSelect((state) => state.navigationReducer.bearerToken);

  return (
    <MDBox position="relative" mb={5}>
      {bearerToken ? (
        <>
          <SubscriptionPlans />
        </>
      ) : (
        <Typography> Not Found </Typography>
      )}
    </MDBox>
  );
}

export default Subscriptions;
