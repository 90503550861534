import { MdOutlineClose } from "react-icons/md";

export const CloseButton = ({ onClick, className = "" }) => {
  return (
    <a
      onClick={onClick}
      className={`flex bg-white hover:bg-pink rounded-full w-26 h-26 items-center justify-center cursor-pointer text-black ${className}`}
    >
      <MdOutlineClose className="m-1 h-[28px] w-[28px]" />
    </a>
  );
};
