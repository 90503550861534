import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import {
  PiFacebookLogo,
  PiInstagramLogo,
  PiTwitterLogo,
  PiTiktokLogo,
} from "react-icons/pi";
import { Box, Divider } from "@mui/material";

export default function Footer() {
  return (
    <Box
      mt={25}
      component="footer"
      sx={{
        p: 6,
      }}
    >
      <Box mb={5}>
        <Divider />
      </Box>
      <Container maxWidth="lg">
        <Grid container spacing={5}>
          <Grid
            item
            xs={12}
            sm={4}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <Typography
              variant="button"
              style={{ display: "block" }}
              color="text.primary"
              gutterBottom
              mb={4}
            >
              Sitemap
            </Typography>
            <Typography variant="overline" color="text.secondary">
              <a href="/privacy-notice">Privacy Notice</a>
            </Typography>
            <Typography variant="overline" color="text.secondary">
              <a href="/terms-of-service">Terms Of Service</a>
            </Typography>
            <Typography variant="overline" color="text.secondary">
              <a href="/digital-art">Digital Art</a>
            </Typography>
            <Typography variant="overline" color="text.secondary">
              <a href="/merch">Merch</a>
            </Typography>
            <Typography variant="overline" color="text.secondary">
              <a href="/about">About</a>
            </Typography>
            <Typography variant="overline" color="text.secondary">
              <a href="/faq">FAQ</a>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography
              variant="button"
              style={{ display: "block" }}
              color="text.primary"
              gutterBottom
              mb={4}
            >
              Contact
            </Typography>
            <Typography
              style={{ display: "block" }}
              variant="overline"
              color="text.secondary"
            >
              651 N Broad St, Suite 201, Middletown, Delaware, US
            </Typography>
            <Typography
              style={{ display: "block" }}
              variant="overline"
              color="text.secondary"
            >
              Email: support@smaiart.com
            </Typography>
            <Typography
              style={{ display: "block" }}
              variant="overline"
              color="text.secondary"
            ></Typography>
          </Grid>
          <Grid item xs={12} sm={4} columnGap={3}>
            <Typography
              variant="button"
              style={{ display: "block" }}
              color="text.primary"
              gutterBottom
              mb={4}
            >
              Follow Us
            </Typography>
            <Grid
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Link
                to="https://www.facebook.com/siyavushm"
                onClick={() => {
                  window.open("https://www.facebook.com/smaiart", "_blank");
                }}
                target="_blank"
                className="actions"
                mr={1}
                color="inherit"
              >
                <PiFacebookLogo size={26} />
              </Link>
              <Link
                to="https://www.instagram.com/siyavushm_art/"
                target="_blank"
                className="actions"
                onClick={() => {
                  window.open(
                    "https://www.instagram.com/siyavushm_art/",
                    "_blank"
                  );
                }}
                mr={1}
                color="inherit"
                sx={{ pl: 1, pr: 1 }}
              >
                <PiInstagramLogo size={26} />
              </Link>
              <Link
                to="https://twitter.com/SiyavushMammad"
                target="_blank"
                className="actions"
                onClick={() => {
                  window.open("https://twitter.com/SiyavushMammad", "_blank");
                }}
                mr={1}
                color="inherit"
              >
                <PiTwitterLogo size={26} />
              </Link>
              <Link
                to="https://www.tiktok.com/@siyavush_mammad"
                onClick={() => {
                  window.open(
                    "https://www.tiktok.com/@siyavush_mammad",
                    "_blank"
                  );
                }}
                target="_blank"
                className="actions"
                mr={1}
                color="inherit"
              >
                <PiTiktokLogo size={26} />
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Box mt={5}>
          <Typography variant="overline" color="text.secondary" align="center">
            {"All Rights Reserved © | "}
            <Link color="inherit" href="https://smaiart.com/">
              smaiart.com
            </Link>
            {" | "}
            {new Date().getFullYear()}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
