import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { Component, useEffect, useState } from "react";

function ToS() {
  return (
    <MDBox style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <MDBox
        style={{
          display: "flex",
          textAlign: "left",
          flexDirection: "column",
          width: "70%",
        }}
      >
        <MDBox>
          <MDTypography sx={{ textAlign: "center" }} variant="h5">
            {"Terms Of Services"}
          </MDTypography>
        </MDBox>
        <MDBox mt={2} mb={1}>
          <MDTypography variant="h6">{"1. Acceptance of Terms"}</MDTypography>
          <MDTypography variant="body2">
            {
              "By accessing and using SMAIART, you agree to comply with and be bound by these Terms of Service. If you do not agree to these terms, please do not use this site."
            }
          </MDTypography>
        </MDBox>

        <MDBox mt={1} mb={1}>
          <MDTypography variant="h6">{"2. User Registration"}</MDTypography>
          <MDTypography variant="body2">
            {
              "Users may be required to register to access certain features. Users must provide accurate, current, and complete information during the registration process.            "
            }
          </MDTypography>
        </MDBox>

        <MDBox mt={1} mb={1}>
          <MDTypography variant="h6">{"3. User Conduct"}</MDTypography>
          <MDTypography variant="body2">
            {
              "Users agree not to use the website in any manner that could interfere with its operation or with another user's enjoyment of the site."
            }
          </MDTypography>
        </MDBox>

        <MDBox mt={1} mb={1}>
          <MDTypography variant="h6">
            {"4. Content Usage and Licensing"}
          </MDTypography>
          <MDTypography variant="body2">
            a. <span style={{ fontWeight: "bold" }}>Free Downloads</span>: Users
            are allowed to download low-resolution images with watermakr for
            free under the Creative Commons (CC1) license. When using these
            images for non-commercial purposes, users must provide appropriate
            attribution to SMAIART.
          </MDTypography>
          <MDTypography variant="body2">
            b. <span style={{ fontWeight: "bold" }}>Paid Downloads</span>:
            High-resolution images are available for a fee. Once purchased,
            users have the right to use the image for personal and commercial
            purposes, but they cannot resell or redistribute the image as-is.
          </MDTypography>
          <MDTypography variant="body2">
            c. <span style={{ fontWeight: "bold" }}>Subscription Model</span>:
            Subscribers can download up to 100 high-resolution images per month,
            depending on their plan. Any unused downloads do not roll over to
            the next month.
          </MDTypography>
        </MDBox>

        <MDBox mt={1} mb={1}>
          <MDTypography variant="h6">{"5. Payments and Refunds"}</MDTypography>
          <MDTypography variant="body2">
            {
              "All payments are processed using [Payment Gateway]. No refunds are offered for downloaded images. Subscription payments are recurring and can be canceled at any time. No refunds are provided for partial months."
            }
          </MDTypography>
        </MDBox>

        <MDBox mt={1} mb={1}>
          <MDTypography variant="h6">
            {"6. User Comments and Feedback"}
          </MDTypography>
          <MDTypography variant="body2">
            {
              "Users may post comments on the website. These comments should be respectful, constructive, and not violate any laws or community standards. SMAIART reserves the right to remove any content that it deems inappropriate."
            }
          </MDTypography>
        </MDBox>

        <MDBox mt={1} mb={1}>
          <MDTypography variant="h6">{"7. Termination"}</MDTypography>
          <MDTypography variant="body2">
            {
              "SMAIART reserves the right to suspend or terminate a user's account for violation of these terms."
            }
          </MDTypography>
        </MDBox>

        <MDBox mt={1} mb={1}>
          <MDTypography variant="h6">{"8. Intellectual Property"}</MDTypography>
          <MDTypography variant="body2">
            {
              'All artwork on SMAIART is the exclusive property of SMAIART LLC and is protected under international copyright laws, except for the free low-resolution images provided under the CC1 license. Users are granted a license to use the artwork as specified under the "Content Usage and Licensing" section but do not gain ownership of the images.'
            }
          </MDTypography>
        </MDBox>

        <MDBox mt={1} mb={1}>
          <MDTypography variant="h6">
            {"9. Liability and Warranty"}
          </MDTypography>
          <MDTypography variant="body2">
            {
              'All content on SMAIART is provided "as is" without any warranties of any kind, either expressed or implied. SMAIART will not be liable for any damages arising from the use of its content.'
            }
          </MDTypography>
        </MDBox>

        <MDBox mt={1} mb={1}>
          <MDTypography variant="h6">
            {"10. Changes to Terms of Service"}
          </MDTypography>
          <MDTypography variant="body2">
            {
              "These terms may be updated or changed at any time without notice. It's the responsibility of users to review these terms regularly."
            }
          </MDTypography>
        </MDBox>

        <MDBox mt={1} mb={1}>
          <MDTypography variant="h6">{"11. Governing Law"}</MDTypography>
          <MDTypography variant="body2">
            {
              "These terms are governed by the laws of Azerbaijan. Any disputes arising out of or related to these terms or the use of SMAIART will be subject to the exclusive jurisdiction of the courts of Azerbaijan."
            }
          </MDTypography>
        </MDBox>

        <MDBox mt={1} mb={1}>
          <MDTypography variant="h6">{"12. Contact Information"}</MDTypography>
          <MDTypography variant="body2">
            For any queries or concerns regarding these terms, please contact{" "}
            <a href="mailto:support@smaiart.com">support@smaiart.com</a>.
          </MDTypography>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export default ToS;
