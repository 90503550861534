import React from "react";
import "./index.css";
import { Typography } from "@mui/material";
const FAQ = ({ faq, index, toggleFAQ }) => {
  return (
    <div
      className={"faq " + (faq.open ? "open" : "")}
      key={index}
      onClick={() => toggleFAQ(index)}
    >
      <div className="faq-question">
      <Typography variant="overline" align="left" pr={5}><strong>{faq.question} </strong></Typography></div>
      {faq.body !== undefined ? <div className="faq-answer">{faq.body} </div> :
      <div className="faq-answer"><Typography variant="overline" align="left" pr={5}>{faq.answer}</Typography></div>}
    </div>
  );
};

export default FAQ;
