import { Dialog, Transition } from "@headlessui/react";
import { Fragment, MutableRefObject } from "react";
import "./index.css";
import { createTheme, useMediaQuery } from "@mui/material";

export const Modal = ({
  isOpen,
  children,
  onClose,
  fullScreen = false,
  initialFocus,
  blurBackground = true,
}) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={onClose}
        initialFocus={initialFocus}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="fixed inset-0 bg-opacity-75 transition-opacity"
            style={{
              backdropFilter: blurBackground ? "blur(15px)" : "",
              WebkitBackdropFilter: blurBackground ? "blur(15px)" : "",
            }}
          />
        </Transition.Child>

        <div
          className="fixed inset-0 z-10"
          style={{
            overflowY: "auto",
          }}
        >
          <div
            className={`flex h-[calc(100dvh)] ${
              fullScreen
                ? `items-stretch justify-stretch overflow-hidden`
                : `justify-center items-center`
            } text-center`}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {children}
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
