import React, { Suspense, useRef, useState, useEffect } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import {
  ContactShadows,
  Environment,
  useGLTF,
  OrbitControls,
} from "@react-three/drei";
import { SketchPicker } from "react-color";
import { proxy, useSnapshot } from "valtio";
import Circle from "@uiw/react-color-circle";

export default function Picker(props) {
  let [items, setItems] = useState([]);
  let [color, setColor] = useState("#ffffff");
  return (
    <div
      style={{
        display: "flex",
        position: "relative !important",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* <HexColorPicker
        style={{ width: "100%" }}
        className="picker"
        color={color}
        onChange={(color) => {
          props.setColor(color);
          setColor(color);
        }}
      /> */}

      <Circle
        picker={"circles"}
        colors={[
          "#000000",
          "#f0f0f0",
          "#F1FEC6",
          "#FF3A20",
          "#B6D6CC",
          "#74A4BC",
          "#9E6240",
        ]}
        onChange={(color) => {
          props.setColor(color.hex);
          setColor(color.hex);
        }}
      />
    </div>
  );
}
