import { Chip, Divider, Grid, Typography } from "@mui/material";
import React, { Component, useEffect, useState } from "react";
import Image from "assets/profile_pic.jpg";
import {
  PiFacebookLogo,
  PiInstagramLogo,
  PiTiktokLogo,
  PiTwitterLogo,
} from "react-icons/pi";
import { Link } from "react-router-dom";
import "./index.css"
import MDBox from "components/MDBox";
import FAQ from "./FAQ";


function FaqHolder() {
  const [faqs, setFaqs] = useState([
    {
      question: "Who can enhance their workflow and creative process with Smaiart?",
      answer:
        "Smaiart is designed for a broad spectrum of users including professionals and enthusiasts in photography, graphic design, digital art, and illustration, who require high-resolution images and creative ideas. It also serves artists who creates images in traditional way. Moreover, businesses in need of images for marketing materials, as well as individuals aiming to polish high-quality visual content.",
      open: false
    },
    {
      question: "How much does Smaiart products cost?",
      answer: "",
      body: <div style={{display: "flex", justifyContent: "flex-start", columnGap: "10px"}}>
        <div class="plan" style={{flex: 1}}>
          <Typography variant="overline"><strong>Free Plan</strong></Typography> <br/>
          <Typography variant="overline"> $0 (Free)</Typography> <br/>
          <Typography variant="overline" style={{textAlign: "left"}}>Unlimited access to low-resolution images with watermarks.</Typography><br/>
          <Typography variant="overline" style={{textAlign: "left"}}>$9 per high resolution image without watermark</Typography><br/>
          <Typography variant="overline" style={{textAlign: "left"}}> Ideal for casual users or those just getting started.</Typography>
        </div>

        <div class="plan" style={{flex: 1}}>
          <Typography variant="overline"><strong>Ultimate Plan</strong></Typography><br/>
          <Typography variant="overline">100 images: $550 ($5.5/image)</Typography><br/>
          <Typography variant="overline" style={{textAlign: "left"}}> Unlimited access to high-resolution images.</Typography><br/>
          <Typography variant="overline" style={{textAlign: "left"}}>Perfect for professionals who need the highest quality images for their work.</Typography>
        </div>
        <div class="plan" style={{flex: 1}}>
          <Typography variant="overline"><strong>Pro Plan</strong></Typography><br/>
          <Typography variant="overline">Variable based on image count.</Typography><br/>
          <ul>
            <li><Typography variant="overline">5 images: $40 ($8/image)</Typography></li>
            <li><Typography variant="overline">10 images: $75 ($7.5/image)</Typography></li>
            <li><Typography variant="overline">25 images: $175 ($7/image)</Typography></li>
            <li><Typography variant="overline">40 images: $240 ($6/image)</Typography></li>
          </ul>
          <Typography variant="overline" style={{textAlign: "left"}}> Access to a specific number of images at a tiered pricing structure.</Typography><br/>
          <Typography variant="overline" style={{textAlign: "left"}}> Great for users with varying image needs who prefer a pay-as-you-go approach.</Typography>
        </div>


      </div>,
      open: false
    },
    {
      question:
        "Will the images have artefacts?",
      answer: "Sometimes, yes. But you can modify purchased images in your own interests.",
      open: false
    },
    {
      question:
        "Is the payment service secure?",
      answer: "Yes, our payment service is 100% secure. Financial processing is handled by Stripe, and we retain no card details on our end. Stripe upholds security standards comparable to those of banks.",
      open: false
    },
    {
      question:
        "What payment methods do you accept?",
      answer: "We accept payments via credit or debit cards, including VISA, MasterCard, American Express, iDeal, SOFORT, Bancontact, Przelewy24, Giropay, EPS, GrabPay, AliPay, and many more. Currently, we do not accept PayPal or cryptocurrency.",
      open: false
    },
    {
      question:
        "Can I get a refund?",
      answer: "Yes, in case of the issues appeared during purchasing process Smaiart will refund full amount. You can contact us for a full refund!",
      open: false
    },
    {
      question:"How do I cancel my subscription?",
      answer: "You can cancel your subscription at any time. Log in, go to the top of Smaiart and click Profile, you will be redirected to Stripe's billing portal where you can download invoices, switch plans and cancel your subscription.",
      open: false
    },
  ]);
  
  
  const toggleFAQ = index => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }
  
        return faq;
      })
    );
  };
  

  useEffect(() => {
    document.title = "Smaiart - FAQ";
  }, []);
  return (
    <Grid container style={{ width: "100%", display: "flex", justifyContent : "center" }} mt={5}>
     <Typography
            variant={"h4"}
            className="sort"
            style={{ color: "black" }}
           
          >
            Frequently Asked Questions
          </Typography>
      <MDBox style={{width: "100%"}} mt={3}>
          <div className="faqs">
            {faqs.map((faq, index) => (
              <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
            ))}
          </div>
          </MDBox>
    </Grid>
  );
}

export default FaqHolder;
