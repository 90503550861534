import { createStore, combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import navigationReducer from "../reducer/navigationReducer";

const rootReducer = combineReducers({
  navigationReducer: navigationReducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const configureStore = () => {
  return createStore(persistedReducer);
};

export default configureStore;

export const store = configureStore();

export const persistor = persistStore(store);
