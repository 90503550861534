/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// custom styles for the NotificationItem
import menuItem from "./styles";
import { Grid } from "@mui/material";

const NotificationItem = forwardRef(({ icon, title, ...rest }, ref) => (
  <MenuItem {...rest} ref={ref} sx={(theme) => menuItem(theme)}>
    <Grid
      component={Link}
      py={0.5}
      display="flex"
      alignItems="center"
      style={{ width: "100%", display: "flex" }}
      lineHeight={1}
    >
      <Grid>
        <MDTypography variant="body1" color="secondary" lineHeight={0.75}>
          {icon}
        </MDTypography>
      </Grid>
      <Grid
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <MDTypography
          variant="button"
          fontWeight="regular"
          style={{
            display: "flex",
            textOverflow: "ellipsis",
            width: icon ? "80%" : "100%",
            overflow: "hidden",
            justifyContent: icon ? "flex-start" : "center",
          }}
          sx={{ ml: 1 }}
        >
          {title}
        </MDTypography>
      </Grid>
    </Grid>
  </MenuItem>
));

// Typechecking props for the NotificationItem
NotificationItem.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default NotificationItem;
