import React from "react";
import { FaFacebookF, FaTwitter } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  authenticateUser,
  getUserAccountTypeInfo,
  getUserInfo,
  registerUser,
} from "../../actions/UserAction";
import { Link } from "react-router-dom";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { Divider, Grid, Switch } from "@mui/material";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

const SignUp = ({ onComplete }) => {
  let dispatch = useDispatch();

  let [email, setEmail] = React.useState("");
  let [name, setName] = React.useState("");
  let [surname, setSurname] = React.useState("");
  let [password, setPassword] = React.useState("");
  let [confirmPassword, setConfirmPassword] = React.useState("");

  const signup = () => {
    registerUser(email, name, surname, password, email)
      .then(function (response) {
        toast(`We have sent confirmation email to ${email}!`);
        onComplete();
      })
      .catch(function (error) {
        toast(error.response.data.title);
      });
  };

  return (
    <div>
      <div className="sign-in-container">
        <div className={"container"} id="container">
          <div className="form-container sign-in-container">
            <MDBox>
              <MDBox
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                mb={5}
              >
                <MDTypography variant="h3">Sign Up</MDTypography>
              </MDBox>

              <MDBox pt={4} pb={3}>
                <MDBox component="form" role="form">
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="Name"
                      fullWidth
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="text"
                      label="Surname"
                      fullWidth
                      value={surname}
                      onChange={(e) => {
                        setSurname(e.target.value);
                      }}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="email"
                      label="Email"
                      fullWidth
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </MDBox>
                  <MDBox mb={2}>
                    <MDInput
                      type="password"
                      label="Password"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                      fullWidth
                    />
                  </MDBox>

                  <MDBox mb={2}>
                    <MDInput
                      type="password"
                      label="Confirm Password"
                      value={confirmPassword}
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                      }}
                      fullWidth
                    />
                  </MDBox>

                  <MDBox mt={4} mb={1}>
                    <MDButton
                      onClick={() => {
                        signup();
                      }}
                      variant="gradient"
                      color="info"
                      fullWidth
                    >
                      sign up
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </MDBox>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
