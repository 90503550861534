import {
  Button,
  Chip,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Drawer,
  Typography,
  Box,
  Paper,
  createTheme,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import React, { Component, useCallback, useEffect, useState } from "react";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import {
  PiHeartFill,
  PiStarFill,
  PiHeart,
  PiStar,
  PiChatText,
  PiDownloadSimple,
  PiTShirt,
  PiEye,
  PiChatCenteredText,
  PiShare,
  PiArrowSquareUpRightLight,
  PiArrowSquareUpRight,
} from "react-icons/pi";
import ImageViewer from "react-simple-image-viewer";
import DrawerComments from "layouts/drawer-comments";
import DrawerLikes from "layouts/drawer-likes";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getArtById } from "actions";
import { toast } from "react-toastify";
import MDBox from "components/MDBox";
import { likeDislikeArt } from "actions";
import { useDispatch, useSelector } from "react-redux";
import { saveUnsaveArt } from "actions";
import axios from "axios";
import { HOST_URL } from "constants";
import SubscriptionNote from "layouts/subscription-note";
import styled from "@emotion/styled";
import { Download, DownloadOutlined, Share } from "@mui/icons-material";
import { PiCrownDuotone } from "react-icons/pi";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  RedditShareButton,
  PinterestShareButton,
} from "react-share";
import {
  FaFacebook,
  FaLink,
  FaPinterest,
  FaReddit,
  FaRegStar,
  FaTwitter,
} from "react-icons/fa";
import { USER } from "constants";
import { getUserAccountData } from "actions/UserAction";
import { getHighResImage } from "actions/UserAction";
import { download } from "utils";
import { ASSETS_URL } from "constants";
import { truncate } from "utils";
import MDTypography from "components/MDTypography";
import {
  BsArrowReturnRight,
  BsArrowUpRightSquare,
  BsBoxArrowUpRight,
  BsCurrencyDollar,
  BsDownload,
  BsFillStarFill,
} from "react-icons/bs";
import { CloseButton } from "components/CloseButton/CloseButton";
import { mapAuthorities } from "utils";
import { PREMIUM_URL } from "constants";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  boxShadow: 24,
  width: "90%",
  maxWidth: "1200px",
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const themeWidth = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

function SelectedDigitalArtModal({ id, onClose }) {
  let [piece, setPiece] = useState({
    imageUrl: "",
    title: "",
    credits: "Siyavush Mammad",
    description: "",
    categories: [],
    likesCount: 0,
    starsCount: 0,
    commentsCount: 0,
    viewsCount: 0,
    downloadsCount: 0,
    liked: false,
    starred: false,
    lowRes: {
      width: 512,
      height: 512,
    },
    highRes: {
      width: 512,
      height: 512,
    },
  });
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  let [drawer, setDrawer] = useState(false);
  let [drawerContent, setDrawerContent] = useState(undefined);
  let bearerToken = useSelector((state) => state.navigationReducer.bearerToken);
  let authorities = useSelector(
    (state) => state.navigationReducer.user.authorities,
  );
  let ipAddress = useSelector((state) => state.navigationReducer.ipAddress);
  let user = useSelector((state) => state.navigationReducer.user);
  let [open, setOpen] = useState(false);
  let attribution = "";
  let attribution1 = "";

  const sm = useMediaQuery(themeWidth.breakpoints.down("sm"));
  const xs = useMediaQuery(themeWidth.breakpoints.down("xs"));
  const md = useMediaQuery(themeWidth.breakpoints.down("md"));
  const lg = useMediaQuery(themeWidth.breakpoints.down("lg"));
  const handleOpen = (event) => setOpen(event.currentTarget);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();

  useEffect(() => {
    getArtById(id, bearerToken)
      .then((response) => {
        let entity = response.data;
        let data = {
          id: entity.id,
          imageUrl: `${
            mapAuthorities(authorities) ? PREMIUM_URL : ASSETS_URL
          }/${entity.imageCompressedLocation}`,
          imageName: entity.imageCompressedLocation,
          imageNameHighRes: entity.imageLocation,
          title: entity.name,
          credits: "Siyavush Mammad",
          description: entity.description,
          categories: entity.categories,
          likesCount: entity.likes,
          commentsCount: entity.comments,
          viewsCount: entity.views,
          downloadsCount: entity.downloads,
          liked: entity.liked,
          starred: entity.saved,
          lowRes: entity.lowRes,
          highRes: entity.highRes,
          price: entity.price,
        };

        setPiece(data);
      })
      .catch((error) => {
        toast("Something went wrong!");
      });
  }, []);

  let onLikeClick = () => {
    likeDislikeArt(id, bearerToken)
      .then((response) => {
        if (piece.liked) {
          setPiece({
            ...piece,
            liked: false,
            likesCount: piece.likesCount - 1,
          });
        } else {
          setPiece({ ...piece, liked: true, likesCount: piece.likesCount + 1 });
        }
      })
      .catch((error) => {
        toast("Something went wrong!");
      });
  };

  useEffect(() => {
    if (piece != undefined) {
      document.title = piece.title;
    }
  }, [piece]);

  let onStarClick = () => {
    if (bearerToken) {
      saveUnsaveArt(id, bearerToken)
        .then((response) => {
          if (piece.starred) {
            setPiece({
              ...piece,
              starred: false,
              starsCount: piece.starsCount - 1,
            });
          } else {
            setPiece({
              ...piece,
              starred: true,
              starsCount: piece.starsCount + 1,
            });
          }
        })
        .catch((error) => {
          toast("Something went wrong!");
        });
    } else {
      toast("You need to be signed in to Bookmark!");
    }
  };

  let onShirtClicked = () => {
    window.location.href = `/merch?art=${id}`;
  };

  const openImageViewer = () => {
    setIsViewerOpen(true);
  };

  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };

  const openComments = () => {
    setDrawerContent("comments");
    toggleDrawer(true);
  };

  const openLikes = () => {
    setDrawerContent("likes");
    toggleDrawer(true);
  };

  const toggleDrawer = (state) => {
    setDrawer(state);
  };

  const renderDrawerContent = (drawerContent) => {
    switch (drawerContent) {
      case "comments":
        return (
          <DrawerComments
            id={id}
            incrementComments={() => {
              setPiece({ ...piece, commentsCount: piece.commentsCount + 1 });
            }}
          />
        );
      case "likes":
        return <DrawerLikes />;

      default:
        return <div></div>;
    }
  };

  return (
    <>
      <Grid
        container
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          rowGap: 10,
        }}
      >
        <Grid
          item
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
          className="flex flex-col gap-y-5 px-5 lg:rounded-r lg:w-2/5 lg:overflow-y-auto custom-scrollbar"
        >
          <img
            src={`${piece.imageUrl}`}
            style={{
              borderLeftRadius: "10px",
              cursor: "pointer",
              height: "100%",
              width: "auto",
              objectFit: "cover",
            }}
            className="flex-1 lg:h-auto"
            srcSet={`${piece.imageUrl}`}
            alt={piece.title}
            loading="lazy"
          />
        </Grid>
        <Grid
          item
          style={{
            flex: 2,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexDirection: "column",
            textAlign: "left",
            borderRight: "10px",
            minWidth: "450px",
          }}
          className="flex flex-col gap-y-5 px-5 lg:rounded-r lg:w-2/5 lg:overflow-y-auto custom-scrollbar pt-5 lg:pt-0"
          p={5}
        >
          <Grid
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div className="lg:flex flex-row lg:sticky lg:top-0 z-[3] pt-5">
              <div className="flex-1"></div>
              <CloseButton onClick={onClose} />
            </div>
          </Grid>

          <Typography variant="h4">{piece.title}</Typography>
          <Typography variant="overline">By {piece.credits}</Typography>

          <Divider variant="middle" style={{ width: "100%" }} />
          <Grid
            item
            container
            style={{
              display: "flex",
              flexDirection: "row",
            }}
            columnGap={1}
            mt={1}
            mb={1}
          >
            {piece.categories.slice(0, 5).map((category) => {
              return (
                <Grid item>
                  <Chip
                    onClick={() => {}}
                    sx={{ my: 0.5 }}
                    className={"chip"}
                    label={category.name}
                    variant="outlined"
                  />
                </Grid>
              );
            })}
          </Grid>
          <Typography variant="overline" align="left" pr={5}>
            {truncate(piece.description, 200)}
          </Typography>

          <Grid
            container
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
            }}
            item
            columnGap={3}
            mt={5}
          >
            <Tooltip title="Like" placement="top">
              <Grid
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {" "}
                <MDBox
                  className="shadow"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    backgroundColor: "#fff",
                    padding: "5px",

                    borderRadius: "50px",
                  }}
                  onClick={onLikeClick}
                >
                  {piece.liked ? (
                    <PiHeartFill className="actions" size={30} fill="#4f4f4f" />
                  ) : (
                    <PiHeart className="actions" size={30} color="#4f4f4f" />
                  )}
                </MDBox>
                <MDTypography variant="overline">
                  {piece.likesCount}
                </MDTypography>
              </Grid>
            </Tooltip>

            <Tooltip title="Views" placement="top">
              <Grid
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {" "}
                <MDBox
                  className="shadow"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    backgroundColor: "#fff",
                    padding: "5px",

                    borderRadius: "50px",
                  }}
                >
                  {<PiEye className="actions" size={30} color="#4f4f4f" />}
                </MDBox>
                <MDTypography variant="overline">
                  {piece.viewsCount}
                </MDTypography>
              </Grid>
            </Tooltip>

            <Tooltip title="Comment" placement="top">
              <Grid
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {" "}
                <MDBox
                  className="shadow"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    backgroundColor: "#fff",
                    padding: "5px",

                    borderRadius: "50px",
                  }}
                  onClick={openComments}
                >
                  <PiChatText className="actions" size={30} color="#4f4f4f" />
                </MDBox>
                <MDTypography variant="overline">
                  {piece.commentsCount}
                </MDTypography>
              </Grid>
            </Tooltip>

            <Tooltip title="Merch" placement="top">
              <Grid
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {" "}
                <MDBox
                  className="shadow"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    backgroundColor: "#fff",
                    padding: "5px",

                    borderRadius: "50px",
                  }}
                  onClick={onShirtClicked}
                >
                  <PiTShirt className="actions" size={30} color="#4f4f4f" />
                </MDBox>
              </Grid>
            </Tooltip>

            <Tooltip title="Share" placement="top">
              <Grid
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {(popupState) => (
                    <React.Fragment>
                      <Grid item>
                        {" "}
                        <MDBox
                          className="shadow"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            backgroundColor: "#fff",
                            padding: "5px",
                            borderRadius: "50px",
                          }}
                          {...bindTrigger(popupState)}
                        >
                          <PiShare
                            className="actions"
                            size={30}
                            fill="#4f4f4f"
                          />
                        </MDBox>
                      </Grid>

                      <Menu {...bindMenu(popupState)}>
                        <MenuItem>
                          {/* <FaFacebook size={24} color="#3b5998" />
                          <Typography ml={2} variant={"overline"}>
                            Facebook

                            
                          </Typography> */}

                          <FacebookShareButton
                            // url={window.location.href}
                            url={"https://smaiart.com"}
                            quote={`Check out ${piece.title} at Smaiart! smaiart.com/digital-art/${piece.id}`}
                            hashtag={"Art"}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <FaFacebook size={24} color="#3b5998" />
                            <Typography ml={2} variant={"overline"}>
                              Facebook
                            </Typography>
                          </FacebookShareButton>
                        </MenuItem>
                        <MenuItem>
                          <TwitterShareButton
                            // url={window.location.href}
                            url={"https://smaiart.com"}
                            title={`Check out ${piece.title} at Smaiart!`}
                            hashtags={piece.categories.map((category) => {
                              return category.name;
                            })}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <FaTwitter color="#1DA1F2" size={24} />
                            <Typography ml={2} variant={"overline"}>
                              Twitter
                            </Typography>
                          </TwitterShareButton>
                        </MenuItem>
                        <MenuItem>
                          <PinterestShareButton
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            url={"https://smaiart.com"}
                            description={`Check out ${piece.title} at Smaiart!`}
                            media={`${piece.imageUrl}`}
                          >
                            <FaPinterest size={24} color="#ff4500" />

                            <Typography ml={2} variant={"overline"}>
                              Pinterest
                            </Typography>
                          </PinterestShareButton>
                        </MenuItem>

                        <MenuItem>
                          <RedditShareButton
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            url={"https://smaiart.com"}
                            title={`Check out ${piece.title} at Smaiart!`}
                          >
                            <FaReddit size={24} color="#ff4500" />

                            <Typography ml={2} variant={"overline"}>
                              Reddit
                            </Typography>
                          </RedditShareButton>
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            toast("Copied to the Clipboard!");
                            navigator.clipboard.writeText(window.location.href);
                          }}
                        >
                          <FaLink size={24} />

                          <Typography ml={2} variant={"overline"}>
                            Copy Link
                          </Typography>
                        </MenuItem>
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
              </Grid>
            </Tooltip>
            <Tooltip title="Open in another tab" placement="top">
              <Grid
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {" "}
                <MDBox
                  className="shadow"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    backgroundColor: "#fff",
                    padding: "5px",

                    borderRadius: "50px",
                  }}
                >
                  {
                    <a href={`/digital-art/${id}`} target="_blank">
                      <PiArrowSquareUpRight
                        className="actions"
                        size={30}
                        color="#4f4f4f"
                      />
                    </a>
                  }
                </MDBox>
              </Grid>
            </Tooltip>
          </Grid>
          <Divider variant="middle" style={{ width: "100%" }} />
          <Typography
            variant="overline"
            style={{ fontSize: "8pt" }}
            align="left"
            pr={5}
          >
            {attribution}
          </Typography>
          <Typography
            mt={1}
            variant="overline"
            style={{ fontSize: "8pt" }}
            align="left"
            pr={5}
          >
            {attribution1}
          </Typography>

          <Grid
            container
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
            rowGap={2}
          >
            <Grid container spacing={1} mt={4} style={{ width: "95%" }}>
              <Grid item xs={12}>
                <Button
                  style={{
                    width: "100%",
                    color: "#424242",
                    borderRadius: "50px",
                    borderColor: "rgba(66, 66, 66, 0.5)",
                    borderWidth: "0.1px",
                    height: "44px",
                  }}
                  onClick={(e) => {
                    if (!bearerToken) {
                      handleOpen(e);
                      return;
                    }

                    if (
                      !(
                        user.authorities.includes("ROLE_PRO") ||
                        user.authorities.includes("ROLE_ULTIMATE")
                      )
                    ) {
                      handleOpen(e);
                      return;
                    }
                  }}
                  variant={"outlined"}
                  size="medium"
                >
                  {`Subscribe `}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <a
                  target="_blank"
                  onClick={() => {
                    axios
                      .get(
                        `${HOST_URL}/api/arts/image/${piece.imageName}/download?compressed=true&ipAddress=${ipAddress}`,
                      )
                      .then((response) => {
                        // window.location.href =
                        //   "data:application/octet-stream;base64," +
                        //   response.data;
                        download(
                          response.data,
                          piece.title +
                            "." +
                            piece.imageNameHighRes.split(".")[1],
                        );
                      });
                  }}
                  filename={`${piece.imageName}.png`}
                  download
                >
                  <Button
                    style={{
                      width: "100%",
                      color: "#424242",
                      borderRadius: "50px",
                      borderColor: "rgba(66, 66, 66, 0.5)",
                      borderWidth: "0.1px",
                      height: "44px",
                    }}
                    variant={"outlined"}
                    size="medium"
                    startIcon={<BsDownload size={18} mr={4} />}
                  >
                    {`Download ${
                      mapAuthorities(authorities) ? "" : "with watermark"
                    } ${piece.lowRes.height} x ${piece.lowRes.width} px`}
                  </Button>
                </a>
              </Grid>
              <Grid
                item
                xs={12}
                onClick={(e) => {
                  if (
                    bearerToken &&
                    (user.subscriptionPlanNumberOfItemsLeft > 0 ||
                      user.subscriptionPlanNumberOfItemsLeft == -1)
                  ) {
                    getHighResImage(bearerToken, piece.imageNameHighRes)
                      .then((response) => {
                        // window.location.href =
                        //   "data:application/octet-stream;base64," +
                        //   response.data;

                        download(
                          response.data,
                          piece.title +
                            "." +
                            piece.imageNameHighRes.split(".")[1],
                        );
                      })
                      .then((response) => {
                        getUserAccountData(bearerToken)
                          .then(function (response) {
                            if (response.error) {
                              toast("Wrong Email/Password");
                            }

                            dispatch({
                              type: USER,
                              payload: response.data,
                            });
                            return response.data.id_token;
                          })
                          .catch(function (error) {
                            toast(error.response.data.title);
                          });
                      });
                  } else {
                    window.location.href = `digital-art/${piece.id}/purchase`;
                  }
                }}
              >
                <Button
                  style={{
                    width: "100%",
                    color: "white",
                    borderRadius: "50px",
                    height: "44px",
                    backgroundColor: "#2bc48a",
                  }}
                  variant={"contained"}
                  size="medium"
                  startIcon={<BsCurrencyDollar size={18} mr={4} />}
                >
                  {bearerToken &&
                  (user.subscriptionPlanNumberOfItemsLeft > 0 ||
                    user.subscriptionPlanNumberOfItemsLeft == -1)
                    ? `Download ${piece.highRes.height} x ${piece.highRes.width} px`
                    : `Buy ${piece.highRes.height} x ${piece.highRes.width} px for $${piece.price}`}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {isViewerOpen && (
        <ImageViewer
          src={[piece.imageUrl]}
          currentIndex={0}
          backgroundStyle={{ backgroundColor: "#000000bf" }}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      )}

      <React.Fragment key={"drawer"}>
        <Drawer
          anchor={"right"}
          open={drawer}
          PaperProps={{
            sx: { width: "25%" },
          }}
          BackdropProps={{ style: { opacity: 0.2 } }}
          onClose={() => toggleDrawer(false)}
        >
          {renderDrawerContent(drawerContent)}
        </Drawer>
      </React.Fragment>

      <Modal
        keepMounted
        open={open}
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.05)",
        }}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>{open && <SubscriptionNote />}</Box>
      </Modal>
    </>
  );
}

export default SelectedDigitalArtModal;
