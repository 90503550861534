import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { Component, useEffect, useState } from "react";

function PrivacyNotice() {
  return (
    <MDBox style={{ width: "100%", display: "flex", justifyContent: "center" }}>
      <MDBox
        style={{
          display: "flex",
          textAlign: "left",
          flexDirection: "column",
          width: "70%",
        }}
      >
        <MDBox>
          <MDTypography sx={{ textAlign: "center" }} variant="h5">
            {"Privacy Notice"}
          </MDTypography>
        </MDBox>
        <MDBox mt={2} mb={1}>
          <MDTypography variant="h6">{"1. Introduction"}</MDTypography>
          <MDTypography variant="body2">
            {
              'This Privacy Notice outlines how SMAIART ("we", "our", or "us") collects, uses, and protects your personal information when you visit or use our platform.'
            }
          </MDTypography>
        </MDBox>

        <MDBox mt={1} mb={1}>
          <MDTypography variant="h6">
            {"2. What Personal Data We Collect"}
          </MDTypography>
          <MDTypography variant="body2">
            <span style={{ fontWeight: "bold" }}>Account Information</span>:
            When you register an account with us, we collect your name, email
            address, password, and other necessary details.
          </MDTypography>
          <MDTypography variant="body2">
            <span style={{ fontWeight: "bold" }}>Payment Information</span>:
            When you make a purchase or subscribe, your payment details are
            processed through a third-party payment processor, and we may
            receive confirmation of your payment.
          </MDTypography>
          <MDTypography variant="body2">
            <span style={{ fontWeight: "bold" }}>User-Generated Content</span>:
            Comments, feedback, or other content you submit to SMAIART.
          </MDTypography>
          <MDTypography variant="body2">
            <span style={{ fontWeight: "bold" }}>Usage Data</span>: Information
            about how you use our platform, such as pages viewed, download
            history, and search queries.
          </MDTypography>
        </MDBox>

        <MDBox mt={1} mb={1}>
          <MDTypography variant="h6">{"3. How We Use Your Data"}</MDTypography>
          <MDTypography variant="body2">
            To process your downloads, purchases, and subscriptions.
          </MDTypography>
          <MDTypography variant="body2">
            To communicate with you, such as sending notifications or
            newsletters.
          </MDTypography>
          <MDTypography variant="body2">
            To improve and personalize your experience on SMAIART.
          </MDTypography>
          To maintain the security and integrity of our platform.
          <MDTypography variant="body2"></MDTypography>
        </MDBox>

        <MDBox mt={1} mb={1}>
          <MDTypography variant="h6">{"4. Cookies and Tracking"}</MDTypography>
          <MDTypography variant="body2">
            We use cookies and similar tracking technologies to enhance user
            experience, analyze website usage, and improve SMAIART's
            performance. You can manage or refuse cookies in your browser
            settings.
          </MDTypography>
        </MDBox>

        <MDBox mt={1} mb={1}>
          <MDTypography variant="h6">
            {"5. How We Share Your Data"}
          </MDTypography>
          <MDTypography variant="body2">
            <span style={{ fontWeight: "bold" }}>Payment Processors</span>: We
            share necessary information with third-party payment processors to
            facilitate transactions.
          </MDTypography>
          <MDTypography variant="body2">
            <span style={{ fontWeight: "bold" }}>Law Enforcement</span>: We may
            disclose your information if required by law or in response to legal
            requests.
          </MDTypography>
          <MDTypography variant="body2">
            <span style={{ fontWeight: "bold" }}>Business Transfers</span>: In
            case of a merger, acquisition, or sale, your data may be
            transferred, but you will be notified of any change in ownership or
            control of your personal data.
          </MDTypography>
        </MDBox>

        <MDBox mt={1} mb={1}>
          <MDTypography variant="h6">{"6. Data Retention"}</MDTypography>
          <MDTypography variant="body2">
            We retain your personal information for as long as necessary to
            fulfill the purposes for which it was collected or as required by
            law.
          </MDTypography>
        </MDBox>

        <MDBox mt={1} mb={1}>
          <MDTypography variant="h6">{"7. Your Rights"}</MDTypography>
          <MDTypography variant="body2">
            Depending on where you reside, you may have rights regarding your
            personal data, including access, correction, deletion, and objection
            to processing. To exercise these rights, please contact us.
          </MDTypography>
        </MDBox>

        <MDBox mt={1} mb={1}>
          <MDTypography variant="h6">{"8. Security"}</MDTypography>
          <MDTypography variant="body2">
            We implement appropriate security measures to protect your personal
            information. However, no method of transmission or storage is 100%
            secure. While we strive to protect your data, we cannot guarantee
            its absolute security.
          </MDTypography>
        </MDBox>

        <MDBox mt={1} mb={1}>
          <MDTypography variant="h6">{"9. Third-Party Links"}</MDTypography>
          <MDTypography variant="body2">
            SMAIART may contain links to external websites. This Privacy Notice
            does not apply to third-party websites, and we are not responsible
            for their content or privacy practices.
          </MDTypography>
        </MDBox>

        <MDBox mt={1} mb={1}>
          <MDTypography variant="h6">
            {"10. Changes to This Privacy Notice"}
          </MDTypography>
          <MDTypography variant="body2">
            We may update this Privacy Notice from time to time. Changes will be
            posted on SMAIART, and the "last updated" date will be revised. It
            is your responsibility to review this notice regularly.
          </MDTypography>
        </MDBox>

        <MDBox mt={1} mb={1}>
          <MDTypography variant="h6">{"11. Contact"}</MDTypography>
          <MDTypography variant="body2">
            If you have questions or concerns about this Privacy Notice or your
            personal data, please contact{" "}
            <a href="mailto:support@smaiart.com">support@smaiart.com</a>.
          </MDTypography>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export default PrivacyNotice;
