import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

const marks = [
  {
    value: 2,
  },
  {
    value: 4,
  },
  {
    value: 6,
  },
  {
    value: 8,
  },
];

function valuetext(value) {
  return `${value}`;
}

function valueLabelFormat(value) {
  return marks.findIndex((mark) => mark.value === value) + 1;
}

export default function GridSlider({ callback }) {
  return (
    <Box sx={{ width: 200 }}>
      <Slider
        aria-label="Restricted values"
        defaultValue={6}
        valueLabelFormat={valueLabelFormat}
        getAriaValueText={valuetext}
        onChange={(e) => {
          callback(e.target.value);
        }}
        step={null}
        valueLabelDisplay="off"
        marks={marks}
        max={8}
      />
    </Box>
  );
}
