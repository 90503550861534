/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// import Dashboard from "layouts/dashboard";
// import Users from "layouts/users";
// import Actions from "layouts/actions";
// import Profile from "layouts/profile";

// @mui icons
import Icon from "@mui/material/Icon";
import ThreeDArt from "layouts/3d-art";
import About from "layouts/about";
import ActivationSuccessful from "layouts/activation";
import AddDigitalArt from "layouts/add-digital-art";
import AddPainting from "layouts/add-painting";
import DigitalArt from "layouts/digital-art";
import DownloadedDigitalArt from "layouts/downloaded-digital-art";
import FaqHolder from "layouts/faq";
import PaintingPurchase from "layouts/painting-purchase";
import ItemPurchase from "layouts/item-purchase";
import PurchaseSuccessful from "layouts/item-purchase/PaymentSuccessful";
import LikedDigitalArt from "layouts/liked-digital-art";
import Merch from "layouts/merch";
import Paintings from "layouts/paintings";
import PrivacyNotice from "layouts/privacy-notice";
import Profile from "layouts/profile";
import SavedDigitalArt from "layouts/saved-digital-art";
import SelectedThreeD from "layouts/selected-3d-art";
import SelectedDigitalArt from "layouts/selected-digital-art";
import SelectedPainting from "layouts/selected-painting";
import { SsoAuthenticationPage } from "layouts/sso-authentication-page";
import SubscriptionCheckout from "layouts/stripe-checkout";
import PaymentSuccessful from "layouts/stripe-checkout/PaymentSuccessful";
import Subscriptions from "layouts/subscriptions";
import ToS from "layouts/tos";
// import SuspiciousPrompts from "layouts/suspicious-prompts";
// import Administrators from "layouts/administrators";
// import Moderators from "layouts/moderators";
// import SignUp from "layouts/authentication/sign-up";
// import AllowedDomains from "layouts/allowed-domains";

const routes = [
  {
    type: "collapse",
    name: "Home",
    key: "digital-art",
    icon: <Icon fontSize="small">pie_chart</Icon>,
    route: "/home",
    component: <DigitalArt />,
  },
  {
    type: "collapse",
    name: "Selected Art",
    key: "selected-art",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/digital-art/:id",
    component: <SelectedDigitalArt />,
  },
  {
    type: "collapse",
    name: "Selected Art",
    key: "selected-art",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/digital-art/:id/purchase",
    component: <ItemPurchase />,
  },
  {
    type: "collapse",
    name: "Selected Art",
    key: "selected-art",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/painting/:id/purchase",
    component: <PaintingPurchase />,
  },
  {
    type: "collapse",
    name: "Account Activation",
    key: "account-activation",
    icon: <Icon fontSize="small">activation</Icon>,
    route: "/account/activate",
    component: <ActivationSuccessful />,
  },
  {
    type: "collapse",
    name: "Subscriptions",
    key: "subscriptions",
    icon: <Icon fontSize="small">subscriptions</Icon>,
    route: "/subscriptions",
    component: <Subscriptions />,
  },
  {
    type: "collapse",
    name: "Selected Art",
    key: "selected-art",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/digital-art/:id/purchase-result",
    component: <PurchaseSuccessful />,
  },
  {
    type: "collapse",
    name: "SSO Authentication Page",
    key: "sso-authentication-page",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/sso/:type",
    component: <SsoAuthenticationPage />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    type: "collapse",
    name: "Subscription",
    key: "subscription",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/subscription-checkout",
    component: <SubscriptionCheckout />,
  },

  {
    type: "collapse",
    name: "Subscription",
    key: "subscription",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/payment-successful",
    component: <PaymentSuccessful />,
  },
  {
    type: "collapse",
    name: "Digital Art",
    key: "digital-art",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/downloads",
    component: <DownloadedDigitalArt />,
  },
  {
    type: "collapse",
    name: "Terms Of Service",
    key: "terms-of-service",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/terms-of-service",
    component: <ToS />,
  },
  {
    type: "collapse",
    name: "Privacy Notice",
    key: "privacy-noyice",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/privacy-notice",
    component: <PrivacyNotice />,
  },
  {
    type: "collapse",
    name: "Digital Art",
    key: "digital-art",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/bookmarks",
    component: <SavedDigitalArt />,
  },
  {
    type: "collapse",
    name: "Digital Art",
    key: "digital-art",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/likes",
    component: <LikedDigitalArt />,
  },

  // {
  //   type: "collapse",
  //   name: "Paintings",
  //   key: "paintings",
  //   icon: <Icon fontSize="small">group</Icon>,
  //   route: "/paintings",
  //   component: <Paintings />,
  // },
  {
    type: "collapse",
    name: "Painting",
    key: "painting",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/paintings/:id",
    component: <SelectedPainting />,
  },
  // {
  //   type: "collapse",
  //   name: "3D Art",
  //   key: "3d-art",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/3d-art",
  //   component: <ThreeDArt />,
  // },
  // {
  //   type: "collapse",
  //   name: "3D Art",
  //   key: "3d-art",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/3d-art/:id",
  //   component: <SelectedThreeD />,
  // },
  {
    type: "collapse",
    name: "Merch",
    key: "merch",
    icon: <Icon fontSize="small">person_3</Icon>,
    route: "/merch",
    component: <Merch />,
  },
  {
    type: "collapse",
    name: "Merch",
    key: "merch",
    icon: <Icon fontSize="small">person_3</Icon>,
    route: "/merch/:id",
    component: <Merch />,
  },
  {
    type: "collapse",
    name: "Add Painting",
    key: "add-painting",
    icon: <Icon fontSize="small">person_3</Icon>,
    route: "/add-painting",
    component: <AddPainting />,
  },
  {
    type: "collapse",
    name: "Add Digital Art",
    key: "add-digital-art",
    icon: <Icon fontSize="small">person_3</Icon>,
    route: "/add-digital-art",
    component: <AddDigitalArt />,
  },
  {
    type: "collapse",
    name: "About",
    key: "about",
    route: "/about",
    component: <About />,
  },
  {
    type: "collapse",
    name: "FAQ",
    key: "faq",
    route: "/faq",
    component: <FaqHolder />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/profile",
    // component: <Profile />,
  },
];

export default routes;
