import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { Component, useEffect, useState } from "react";

import { Elements, PaymentElement } from "@stripe/react-stripe-js";
import getStripe from "layouts/stripe-container";
import {
  Button,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import CheckoutForm from "./CheckoutForm";
import PaymentForm from "./PaymentForm";
import { useParams, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getPlan } from "actions";
import { getArtById } from "actions";
import { toast } from "react-toastify";
import { HOST_URL } from "constants";
import { ASSETS_URL } from "constants";
import { ORIGINAL_URL } from "constants";
import { getPaintingById } from "actions";

const themeWidth = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

function PaintingPurchase() {
  let [isCheckoutForm, setIsCheckoutForm] = useState(false);
  let [piece, setPiece] = useState({
    images: [],
    title: "",
    credits: "Siyavush Mammad",
    description: "",
    categories: [],
    likesCount: 0,
    starsCount: 0,
    commentsCount: 0,
    viewsCount: 0,
    downloadsCount: 0,
    canvasHeight: 60,
    canvasWidth: 40,
    liked: false,
    starred: false,
    lowRes: {
      width: 512,
      height: 512,
    },
    highRes: {
      width: 512,
      height: 512,
    },
  });

  const sm = useMediaQuery(themeWidth.breakpoints.down("sm"));
  const xs = useMediaQuery(themeWidth.breakpoints.down("xs"));
  const md = useMediaQuery(themeWidth.breakpoints.down("md"));
  const lg = useMediaQuery(themeWidth.breakpoints.down("lg"));
  const { id } = useParams();

  let bearerToken = useSelector((state) => state.navigationReducer.bearerToken);
  let [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    stateProvince: "",
    zipCode: "",
    country: "",
    telephone: "",
    deliveryInformation: "",
  });

  useEffect(() => {
    getPaintingById(id, bearerToken)
      .then((response) => {
        let entity = response.data;
        let data = {
          id: entity.id,
          imageName: entity.imageCompressedLocation,
          imageNameHighRes: entity.imageLocation,
          title: entity.name,
          credits: "Siyavush Mammad",
          description: entity.description,
          categories: entity.categories,
          likesCount: entity.likes,
          commentsCount: entity.comments,
          viewsCount: entity.views,
          canvasHeight: entity.canvasHeight,
          canvasWidth: entity.canvasWidth,
          downloadsCount: entity.downloads,
          liked: entity.liked,
          starred: entity.saved,
          lowRes: entity.lowRes,
          highRes: entity.highRes,
          price: entity.price,
          images: entity.imageCompressedLocation.map((id, index) => {
            return { index: index, url: `${ORIGINAL_URL}/${id}` };
          }),
          isSold: entity.isSold,
        };

        setPiece(data);
        console.log(entity);
      })
      .catch((error) => {
        console.log(error);
        toast("Something went wrong!");
      });
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  return (
    <Grid container style={{ width: "100%", padding: md ? "15px" : 0 }} pt={10}>
      <Grid container style={{ display: "flex", flexDirection: "row" }}>
        <Grid
          item
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <img
            src={piece?.images.length > 0 ? piece.images[0].url : ""}
            style={{
              maxWidth: "512px",
              maxHeight: "512px",
              borderRadius: "10px",
            }}
            srcSet={piece?.images.length > 0 ? piece.images[0].url : ""}
            alt={""}
            loading="lazy"
          />
        </Grid>
        <Grid
          item
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexDirection: "column",
            textAlign: "left",
          }}
          className="w-full"
        >
          {piece ? (
            <>
              <p className="px-6 mt-10 md:mt-0 font-semibold text-center">{`${piece.title} (${piece.canvasWidth} x ${piece.canvasHeight} cm) - $${piece.price}`}</p>
            </>
          ) : (
            <>
              <Typography variant="h3">{`Smaiart Art Purchase`}</Typography>
            </>
          )}

          {!isCheckoutForm ? (
            <div class="w-full md:max-w-full mx-auto">
              <div class="px-6">
                <Stack spacing={2}>
                  <p className="pt-3 text-gray-400 text-sm text-center">
                    {" "}
                    Please enter delivery information{" "}
                  </p>
                  <TextField
                    label="Email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    fullWidth
                    required
                  />
                  <TextField
                    label="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    fullWidth
                    required
                  />
                  <TextField
                    label="Address Line 1"
                    name="addressLine1"
                    value={formData.addressLine1}
                    onChange={handleChange}
                    fullWidth
                    required
                  />
                  <TextField
                    label="Address Line 2"
                    name="addressLine2"
                    value={formData.addressLine2}
                    onChange={handleChange}
                    fullWidth
                  />
                  <TextField
                    label="City"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    fullWidth
                    required
                  />
                  <TextField
                    label="State/Province"
                    name="stateProvince"
                    value={formData.stateProvince}
                    onChange={handleChange}
                    fullWidth
                    required
                  />
                  <TextField
                    label="Zip/Postal Code"
                    name="zipCode"
                    value={formData.zipCode}
                    onChange={handleChange}
                    fullWidth
                    required
                  />
                  <TextField
                    label="Country"
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                    fullWidth
                    required
                  />
                  <TextField
                    label="Phone Number"
                    name="telephone"
                    value={formData.telephone}
                    onChange={handleChange}
                    fullWidth
                    required
                  />
                  <TextField
                    label="Delivery Information"
                    name="deliveryInformation"
                    value={formData.deliveryInformation}
                    placeholder="floor/door, lock code etc."
                    onChange={handleChange}
                    fullWidth
                    multiline
                    rows={4}
                  />
                </Stack>
                <Button
                  variant="contained"
                  className="mt-3 MuiButtonBase-root MuiFab-root MuiFab-extended MuiFab-sizeLarge MuiFab-info MuiFab-root MuiFab-extended MuiFab-sizeLarge MuiFab-info css-12vsk1y-MuiButtonBase-root-MuiFab-root"
                  style={{
                    width: "100%",
                    color: "white",
                    borderRadius: "100px",
                  }}
                  onClick={(e) => {
                    setIsCheckoutForm(true);
                  }}
                  id="submit"
                >
                  <span id="button-text">
                    {isLoading ? (
                      <div className="spinner" id="spinner"></div>
                    ) : (
                      "Proceed to Checkout"
                    )}
                  </span>
                </Button>
              </div>
            </div>
          ) : (
            <div className="px-3">
              <p className="py-3 text-gray-400 text-sm text-center">
                Our team will contact you with delivery information.
              </p>
              <PaymentForm deliveryInformation={formData} itemId={id} />
              <Button
                variant="outlined"
                className="mt-3"
                style={{
                  width: "100%",
                  color: "#0066FF",
                  borderRadius: "100px",
                }}
                onClick={(e) => {
                  setIsCheckoutForm(false);
                }}
                id="submit"
              >
                <span id="button-text">Back</span>
              </Button>
            </div>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PaintingPurchase;
