import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Link } from "react-router-dom";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { Divider, Grid, Switch } from "@mui/material";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { SIGN_IN } from "constants";
import { USER } from "constants";
import SubscriptionPlans from "layouts/subscription-plans";

const SubscriptionNote = () => {
  return (
    <div>
      <div className="sign-in-container">
        <div className={"container"} id="container">
          <div className="form-container sign-in-container">
            <MDBox>
              <MDBox
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <SubscriptionPlans />
              </MDBox>
            </MDBox>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionNote;
