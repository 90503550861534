import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Divider,
  Grid,
  PaginationItem,
  Typography,
} from "@mui/material";
import { useSelect } from "@react-three/drei";
import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { Component, useState } from "react";
import { useSelector } from "react-redux";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SubscriptionPlans from "layouts/subscription-plans";

function Profile({ children }) {
  let user = useSelector((state) => state.navigationReducer.user);
  let bearerToken = useSelect((state) => state.navigationReducer.bearerToken);

  const accordions = [
    {
      title: "What is the difference between Standard and Enhanced Licenses? ",
      description:
        "The Standard License is designed to work for most creative and usage needs. The Enhanced License offers many more usage rights, allowing you to use images in merchandise, web templates, print templates, and commercial spaces. Please visit our ",
    },
    {
      title: "How do Smaiart subscription plans work?",
    },
    {
      title: "Can I upgrade or downgrade to a different plan?",
    },
    {
      title: "Can I cancel my plan if I don’t need it anymore?",
    },
    {
      title: "Can I get a refund if I cancel my plan?",
    },
  ];

  return (
    <MDBox position="relative" mb={5}>
      {bearerToken ? (
        <>
          <SubscriptionPlans />
          <Box
            sx={{
              position: "relative",
              mt: 3,
              mx: 3,
              py: 2,
              px: 2,
            }}
          >
            <Grid container spacing={3} alignItems="center">
              <Grid item>
                <MDAvatar
                  src={`https://api.dicebear.com/5.x/shapes/png?seed=Murad.png`}
                  alt="profile-image"
                  size="xl"
                  shadow="sm"
                />
              </Grid>
              <Grid item>
                <MDBox height="100%" mt={0.5} lineHeight={1}>
                  <MDTypography variant="h5" fontWeight="medium">
                    {`${user.firstName ? user.firstName : ""} ${
                      user.lastName ? user.lastName : ""
                    }`}
                  </MDTypography>
                  <MDTypography
                    variant="button"
                    color="text"
                    fontWeight="regular"
                  >
                    {`${user.email ? user.email : ""}`}
                  </MDTypography>
                </MDBox>
              </Grid>
            </Grid>
          </Box>

          <Divider sx={{ mb: 5, mt: 5 }} />

          <Typography variant="h5">Frequently asked questions</Typography>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ width: "80%" }}>
              {accordions.map((accordion) => {
                return (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography variant="overline">
                        {accordion.title}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ textAlign: "left" }}>
                      <Typography variant="overline">
                        {accordion.description}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </div>
          </Box>
        </>
      ) : (
        <Typography> Not Found </Typography>
      )}
    </MDBox>
  );
}

export default Profile;
