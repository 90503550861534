import {
  Box,
  Chip,
  Grid,
  ImageList,
  ImageListItem,
  Typography,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import { getAllDownloads } from "actions";
import { getAllSaves } from "actions";
import { getAllArts } from "actions";
import MDBox from "components/MDBox";
import { ASSETS_URL } from "constants";
import { HOST_URL } from "constants";
import React, { Component, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

function SavedDigitalArt() {
  const [itemData, setItemData] = useState([]);
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const xs = useMediaQuery(theme.breakpoints.down("xs"));
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  let [isLoading, setIsLoading] = useState(false);
  const observerTarget = useRef(null);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  let [xTotalCount, setXTotalCount] = useState(null);
  let PAGE_SIZE = 40;
  let bearerToken = useSelector((state) => state.navigationReducer.bearerToken);

  let fetchArts = () => {
    setIsLoading(true);
    setError(null);

    getAllSaves(bearerToken, page, PAGE_SIZE)
      .then((response) => {
        let data = response.data;

        data = data.map((entity) => {
          return {
            id: entity.id,
            name: entity.name,
            imageCompressedLocation: `]${ASSETS_URL}/${entity.imageCompressedLocation}`,
            resolution: entity.resolution,
          };
        });

        setItemData((prevData) => [...prevData, ...data]);

        setXTotalCount((prevData) => response.headers["x-total-count"]);
      })
      .catch((e) => {
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  let renderNumberOfColumns = () => {
    if (xs) return 1;
    if (sm) return 1;
    if (md) return 4;
    if (lg) return 6;
    return 6;
  };

  useEffect(() => {
    fetchArts();
  }, [page]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          if (
            xTotalCount === null ||
            itemData === [] ||
            xTotalCount > itemData.length
          )
            setPage((prevPage) => prevPage + 1);
        }
      },
      { threshold: 1 },
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget]);

  return (
    <MDBox pl={3}>
      <Grid container style={{ display: "flex", justifyContent: "center" }}>
        {/* <Box sx={{ width: 500, height: 450, overflowY: "scroll" }}> */}
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
          }}
          mb={5}
          ml={3}
        >
          <Typography variant="h4">My Bookmarks</Typography>
        </Box>
        <Grid item>
          {itemData.length == 0 ? (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
              mb={5}
              ml={3}
            >
              <Typography variant="h5">No images to show</Typography>
            </Box>
          ) : (
            <ImageList variant="masonry" cols={renderNumberOfColumns()} gap={8}>
              {itemData.map((item) => (
                <ImageListItem key={item.imageCompressedLocation}>
                  <img
                    width={item.resolution.width}
                    height={item.resolution.height}
                    onClick={() => {
                      window.location.href = `/digital-art/${item.id}`;
                    }}
                    className="image-tile"
                    loading="lazy"
                    style={{ backgroundColor: "#000" }}
                    src={`${item.imageCompressedLocation}`}
                    srcSet={`${item.imageCompressedLocation}`}
                    alt={item.name}
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          )}
          {<div ref={observerTarget}></div>}
          {/* </Box> */}
        </Grid>
      </Grid>
    </MDBox>
  );
}

export default SavedDigitalArt;
