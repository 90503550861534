import React, { Component, useEffect, useState } from "react";

import { Divider, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import MDBox from "components/MDBox";
import { HomeMiniOutlined } from "@mui/icons-material";
import { PiHouse, PiHouseBold, PiHouseLine } from "react-icons/pi";
import { HOST_URL } from "constants";
import { getUserAccountData } from "actions/UserAction";
import { USER } from "constants";
import { toast } from "react-toastify";
import { ASSETS_URL } from "constants";

function PaymentSuccessful() {
  let user = useSelector((state) => state.navigationReducer.user);
  let bearerToken = useSelector((state) => state.navigationReducer.bearerToken);

  let dispatch = useDispatch();

  useEffect(() => {
    getUserAccountData(bearerToken)
      .then(function (response) {
        if (response.error) {
          toast("Wrong Email/Password");
        }

        dispatch({
          type: USER,
          payload: response.data,
        });
        return response.data.id_token;
      })
      .catch(function (error) {
        toast(error.response.data.title);
      });
  }, []);

  return (
    <Grid container style={{ width: "100%" }} pt={10}>
      <Grid container style={{ display: "flex", flexDirection: "row" }}>
        <Grid
          item
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <img
            src={`${ASSETS_URL}/22b08b19-f2a0-4593-98ff-6aee4e0b3d77.jpg`}
            style={{
              maxWidth: "512px",
              maxHeight: "512px",
              borderRadius: "10px",
            }}
            srcSet={`${ASSETS_URL}/22b08b19-f2a0-4593-98ff-6aee4e0b3d77.jpg`}
            alt={""}
            loading="lazy"
          />
        </Grid>
        <Grid
          item
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexDirection: "column",
            textAlign: "left",
          }}
        >
          <Typography variant="h3">{`Smaiart Subscription Purchase`}</Typography>
          <Typography variant="overline">{`Successful Payment`}</Typography>

          <Divider variant="middle" style={{ width: "100%" }} />

          <Typography variant="overline">
            {`We have sent you subscription purchase receipt on `}{" "}
            <a href={`mailto:${user.email}`}>{`${user.email}`}</a>
          </Typography>

          <MDBox
            mt={20}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              width: "100%",
            }}
            onClick={() => {
              window.location.href = "/home";
            }}
          >
            <PiHouseBold style={{ marginRight: 10 }} size={20} />
            <Typography
              variant="overline"
              style={{ fontWeight: "bold", textDecoration: "underline" }}
            >
              {" "}
              Back to Home
            </Typography>
          </MDBox>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default PaymentSuccessful;
