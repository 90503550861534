import {
  MENU_CHANGE,
  LANGUAGE_CHANGE,
  SIGN_IN,
  USER,
  UPDATE_SUBSCRIPTION,
  SET_IP,
  SET_AUTH,
} from "../constants";
const initialState = {
  ipAddress: "localhost",
  language: "en",
  bearerToken: undefined,
  user: {
    login: undefined,
    email: undefined,
    imageUrl: undefined,
    authorities: [],
  },
};

const navigationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        bearerToken: action.payload,
      };

    case UPDATE_SUBSCRIPTION:
      return {
        ...state,
        user: action.payload,
      };

    case USER:
      return {
        ...state,
        user: action.payload,
      };

    case SET_IP:
      return {
        ...state,
        ipAddress: action.payload,
      };

    case SET_AUTH:
      return {
        ...state,
        user: { ...state.user, authorities: action.payload },
      };
    default:
      return state;
  }
};
export default navigationReducer;
