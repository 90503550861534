import { Avatar, Card, Grid, Paper } from "@mui/material";
// import { getShortLikes } from "actions/AdminAction";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import React, { Component, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { formatDateTime } from "utils";

function DrawerLikes({ shortId }) {
  let [likes, setLikes] = useState([]);
  let [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   setIsLoading(true);
  //   getShortLikes(bearerToken, 0, shortId)
  //     .then((response) => {
  //       let likes = response.data;

  //       setLikes(likes);
  //     })
  //     .catch((error) => {
  //       toast("Something went wrong");
  //     })
  //     .finally(() => {
  //       setIsLoading(false);
  //     });
  // }, [shortId]);

  return (
    <MDBox style={{ width: "400px" }}>
      <MDBox>
        <MDTypography variant="h5" color="black" p={3}>
          {"Likes"}
        </MDTypography>
        {likes?.map((like) => {
          return (
            <MDBox
              style={{
                padding: "20px",

                paddingTop: "20px",
                paddingBottom: "20px",
                margin: "5px",
                boxShadow: "none",
                borderBottom: "0.5px solid #dbdbdb",
              }}
            >
              <Grid container spacing={2}>
                <Grid item>
                  <Avatar
                    alt={like.username}
                    sx={{ width: 31, height: 31 }}
                    src={
                      like.profilePic
                        ? like.profilePic
                        : `https://api.dicebear.com/5.x/shapes/png?seed=${like.username}.png`
                    }
                    style={{ marginTop: 5 }}
                  />
                </Grid>
                <Grid
                  justifyContent="left"
                  style={{ flexDirection: "column", display: "flex" }}
                  item
                  xs
                  zeroMinWidth
                >
                  <MDTypography variant="button" color="info">
                    @{like.username}
                  </MDTypography>
                  <MDTypography variant="caption">
                    {formatDateTime(like.createdAt)}
                  </MDTypography>
                </Grid>
              </Grid>
            </MDBox>
          );
        })}
      </MDBox>
    </MDBox>
  );
}

export default DrawerLikes;
