import {
  Button,
  Chip,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Drawer,
  Typography,
  Box,
  Paper,
  createTheme,
  useMediaQuery,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import React, { Component, useCallback, useEffect, useState } from "react";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import {
  PiHeartFill,
  PiStarFill,
  PiHeart,
  PiStar,
  PiChatText,
  PiDownloadSimple,
  PiTShirt,
  PiEye,
  PiChatCenteredText,
} from "react-icons/pi";
import ImageViewer from "react-simple-image-viewer";
import DrawerComments from "layouts/drawer-comments";
import DrawerLikes from "layouts/drawer-likes";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getPaintingById } from "actions";
import { toast } from "react-toastify";
import MDBox from "components/MDBox";
import { likeDislikeArt } from "actions";
import { useDispatch, useSelector } from "react-redux";
import { saveUnsaveArt } from "actions";
import axios from "axios";
import { HOST_URL } from "constants";
import SubscriptionNote from "layouts/subscription-note";
import styled from "@emotion/styled";
import { Download, DownloadOutlined, Share } from "@mui/icons-material";
import { PiCrownDuotone } from "react-icons/pi";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  RedditShareButton,
  PinterestShareButton,
} from "react-share";
import {
  FaFacebook,
  FaLink,
  FaPinterest,
  FaReddit,
  FaTwitter,
} from "react-icons/fa";
import { USER } from "constants";
import { getUserAccountData } from "actions/UserAction";
import { getHighResImage } from "actions/UserAction";
import { download } from "utils";
import { ASSETS_URL } from "constants";
import { mapAuthorities } from "utils";
import { PREMIUM_URL } from "constants";
import { downloadImage } from "utils";
import { ORIGINAL_URL } from "constants";
import { parseDate } from "utils";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "10px",
  boxShadow: 24,
  width: "90%",
  maxWidth: "1200px",
};

const themeWidth = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

function SelectedPainting() {
  let [piece, setPiece] = useState({
    imageUrl: "",
    title: "",
    credits: "Siyavush Mammadov",
    description: "",
    canvasDescription: "",
    categories: [],
    likesCount: 0,
    starsCount: 0,
    commentsCount: 0,
    viewsCount: 0,
    downloadsCount: 0,
    dateCreated: undefined,
    price: 0,
    isSold: true,
    liked: false,
    starred: false,
    lowRes: {
      width: 512,
      height: 512,
    },
    highRes: {
      width: 512,
      height: 512,
    },
  });
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const { id } = useParams();
  let [drawer, setDrawer] = useState(false);
  let [drawerContent, setDrawerContent] = useState(undefined);
  let bearerToken = useSelector((state) => state.navigationReducer.bearerToken);
  let ipAddress = useSelector((state) => state.navigationReducer.ipAddress);
  let user = useSelector((state) => state.navigationReducer.user);
  let [open, setOpen] = useState(false);
  let [selectedImage, setSelectedImage] = useState(undefined);
  let attribution =
    "All original artworks from smaiart.com ship worldwide with shipping and packaging costs included in the price. Paintings on canvas are shipped unframed in a protective tube.";
  let attribution1 = "";
  let authorities = useSelector(
    (state) => state.navigationReducer.user.authorities,
  );

  const sm = useMediaQuery(themeWidth.breakpoints.down("sm"));
  const xs = useMediaQuery(themeWidth.breakpoints.down("xs"));
  const md = useMediaQuery(themeWidth.breakpoints.down("md"));
  const lg = useMediaQuery(themeWidth.breakpoints.down("lg"));
  const handleOpen = (event) => setOpen(event.currentTarget);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();

  useEffect(() => {
    getPaintingById(id, bearerToken)
      .then((response) => {
        let entity = response.data;
        let data = {
          id: entity.id,
          imageName: entity.imageCompressedLocation,
          imageNameHighRes: entity.imageLocation,
          title: entity.name,
          credits: "Siyavush Mammadov",
          description: entity.description,
          canvasDescription: entity.canvasDescription,
          dateCreated: entity.dateCreated,
          categories: entity.categories,
          likesCount: entity.likes,
          commentsCount: entity.comments,
          viewsCount: entity.views,
          downloadsCount: entity.downloads,
          liked: entity.liked,
          starred: entity.saved,
          lowRes: entity.lowRes,
          highRes: entity.highRes,
          price: entity.price,
          images: entity.imageCompressedLocation.map((id, index) => {
            return { index: index, url: `${ORIGINAL_URL}/${id}` };
          }),
          isSold: entity.isSold,
        };

        setSelectedImage({
          index: 0,
          url: `${ORIGINAL_URL}/${entity.imageCompressedLocation[0]}`,
        });

        console.log(data);
        setPiece(data);
      })

      .catch((error) => {
        toast("Something went wrong!");
      });
  }, []);

  let onLikeClick = () => {
    likeDislikeArt(id, bearerToken)
      .then((response) => {
        if (piece.liked) {
          setPiece({
            ...piece,
            liked: false,
            likesCount: piece.likesCount - 1,
          });
        } else {
          setPiece({ ...piece, liked: true, likesCount: piece.likesCount + 1 });
        }
      })
      .catch((error) => {
        toast("Something went wrong!");
      });
  };

  let onStarClick = () => {
    if (bearerToken) {
      saveUnsaveArt(id, bearerToken)
        .then((response) => {
          if (piece.starred) {
            setPiece({
              ...piece,
              starred: false,
              starsCount: piece.starsCount - 1,
            });
          } else {
            setPiece({
              ...piece,
              starred: true,
              starsCount: piece.starsCount + 1,
            });
          }
        })
        .catch((error) => {
          toast("Something went wrong!");
        });
    } else {
      toast("You need to be signed in to Bookmark!");
    }
  };

  let onShirtClicked = () => {
    window.location.href = `/merch?art=${id}`;
  };

  const openImageViewer = () => {
    setIsViewerOpen(true);
  };

  const closeImageViewer = () => {
    setIsViewerOpen(false);
  };

  const openComments = () => {
    setDrawerContent("comments");
    toggleDrawer(true);
  };

  const openLikes = () => {
    setDrawerContent("likes");
    toggleDrawer(true);
  };

  const toggleDrawer = (state) => {
    setDrawer(state);
  };

  useEffect(() => {
    if (piece != undefined) {
      document.title = "Smaiart - " + piece.title;
    }
  }, [piece]);

  const renderDrawerContent = (drawerContent) => {
    switch (drawerContent) {
      case "comments":
        return (
          <DrawerComments
            id={id}
            incrementComments={() => {
              setPiece({ ...piece, commentsCount: piece.commentsCount + 1 });
            }}
          />
        );
      case "likes":
        return <DrawerLikes />;

      default:
        return <div></div>;
    }
  };

  return (
    <Grid container style={{ width: "100%" }} className="p-5" pt={10}>
      <div
        className="md:flex gap-10"
        // style={{ display: "flex", flexDirection: "row", rowGap: 100 }}
      >
        <div
          className="md:w-3/5"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Grid item>
            {selectedImage && (
              <div class="grid gap-4">
                <div>
                  <img
                    class="h-auto w-full max-w-full rounded-lg object-cover object-center md:h-[480px] cursor-pointer"
                    src={`${selectedImage.url}`}
                    onClick={() => openImageViewer()}
                    alt=""
                  />
                </div>
                <div class="grid grid-cols-5 h-30 gap-1">
                  {piece.images.map((image) => {
                    return (
                      <div>
                        <img
                          src={image.url}
                          onClick={() =>
                            setSelectedImage({
                              index: image.index,
                              url: image.url,
                            })
                          }
                          class={`object-cover object-center h-20 rounded-lg cursor-pointer transition-all hover:brightness-50 
                            ${selectedImage.index === image.index && " border-2 border-black"}`}
                          alt="gallery-image"
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </Grid>
        </div>
        <div
          className="flex-1"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            flexDirection: "column",
            textAlign: "left",
            padding: md ? "15px" : "0px",
          }}
        >
          <Typography variant="h3">{piece.title}</Typography>
          <p className="text-md">By {piece.credits}</p>

          <Divider variant="middle" style={{ width: "100%" }} />
          <p
            variant="overline"
            className="mb-2 font-semibold"
            align="left"
            pr={5}
          >
            {piece.canvasDescription}
          </p>

          <p variant="overline" align="left" pr={5}>
            {piece.description}
          </p>

          <p
            variant="overline"
            className="mt-5 font-semibold"
            align="left"
            pr={5}
          >
            {piece.dateCreated ? `Created: ${piece.dateCreated}` : ""}
          </p>

          <Grid item container className="w-full" mt={4}>
            <div className="w-full">
              <Grid
                container
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
                item
                columnGap={3}
                mt={5}
              >
                <Grid item>
                  {" "}
                  <MDBox
                    className="shadow"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      backgroundColor: "#fff",
                      padding: "5px",

                      borderRadius: "50px",
                    }}
                    onClick={onLikeClick}
                  >
                    {piece.liked ? (
                      <PiHeartFill
                        className="actions"
                        size={30}
                        fill="#4f4f4f"
                      />
                    ) : (
                      <PiHeart className="actions" size={30} color="#4f4f4f" />
                    )}
                  </MDBox>
                  <Grid item>
                    <p
                      className="w-full text-center text-sm mt-2"
                      variant="overline"
                    >
                      {piece.likesCount}
                    </p>
                  </Grid>
                </Grid>

                <Grid item>
                  {" "}
                  <MDBox
                    className="shadow"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      backgroundColor: "#fff",
                      padding: "5px",

                      borderRadius: "50px",
                    }}
                    onClick={openComments}
                  >
                    <PiChatText className="actions" size={30} color="#4f4f4f" />
                  </MDBox>
                  <Grid item>
                    <p
                      className="w-full text-center text-sm mt-2"
                      variant="overline"
                    >
                      {piece.commentsCount}
                    </p>
                  </Grid>
                </Grid>

                <Grid item>
                  {" "}
                  <MDBox
                    className="shadow"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                      backgroundColor: "#fff",
                      padding: "5px",

                      borderRadius: "50px",
                    }}
                  >
                    <PiEye className="actions" size={30} color="#4f4f4f" />
                  </MDBox>
                  <Grid item>
                    <p
                      className="w-full text-center text-sm mt-2"
                      variant="overline"
                    >
                      {piece.viewsCount}
                    </p>
                  </Grid>
                </Grid>

                <Grid item>
                  <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                      <React.Fragment>
                        <MDBox
                          className="shadow"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            backgroundColor: "#fff",
                            padding: "5px",

                            borderRadius: "50px",
                          }}
                          {...bindTrigger(popupState)}
                        >
                          <Share className="actions" size={30} fill="#4f4f4f" />
                        </MDBox>
                        <Menu {...bindMenu(popupState)}>
                          <MenuItem>
                            {/* <FaFacebook size={24} color="#3b5998" />
                        <Typography ml={2} variant={"overline"}>
                          Facebook

                          
                        </Typography> */}

                            <FacebookShareButton
                              // url={window.location.href}
                              url={"https://smaiart.com"}
                              quote={`Check out ${piece.title} at Smaiart! smaiart.com/digital-art/${piece.id}`}
                              hashtag={"Art"}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <FaFacebook size={24} color="#3b5998" />
                              <Typography ml={2} variant={"overline"}>
                                Facebook
                              </Typography>
                            </FacebookShareButton>
                          </MenuItem>
                          <MenuItem>
                            <TwitterShareButton
                              // url={window.location.href}
                              url={"https://smaiart.com"}
                              title={`Check out ${piece.title} at Smaiart!`}
                              hashtags={piece.categories.map((category) => {
                                return category.name;
                              })}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <FaTwitter color="#1DA1F2" size={24} />
                              <Typography ml={2} variant={"overline"}>
                                Twitter
                              </Typography>
                            </TwitterShareButton>
                          </MenuItem>
                          <MenuItem>
                            <PinterestShareButton
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              url={"https://smaiart.com"}
                              description={`Check out ${piece.title} at Smaiart!`}
                              media={`${piece.imageUrl}`}
                            >
                              <FaPinterest size={24} color="#ff4500" />

                              <Typography ml={2} variant={"overline"}>
                                Pinterest
                              </Typography>
                            </PinterestShareButton>
                          </MenuItem>

                          <MenuItem>
                            <RedditShareButton
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              url={"https://smaiart.com"}
                              title={`Check out ${piece.title} at Smaiart!`}
                            >
                              <FaReddit size={24} color="#ff4500" />

                              <Typography ml={2} variant={"overline"}>
                                Reddit
                              </Typography>
                            </RedditShareButton>
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              toast("Copied to the Clipboard!");
                              navigator.clipboard.writeText(
                                window.location.href,
                              );
                            }}
                          >
                            <FaLink size={24} />

                            <Typography ml={2} variant={"overline"}>
                              Copy Link
                            </Typography>
                          </MenuItem>
                        </Menu>
                      </React.Fragment>
                    )}
                  </PopupState>
                </Grid>
              </Grid>

              <Grid
                container
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className="w-full"
                mt={5}
                rowGap={2}
              >
                <Grid container spacing={1} mt={4} className="w-full">
                  <Grid item xs={12}>
                    <Button
                      style={{
                        width: "100%",
                        color: "white",
                        borderRadius: "50px",
                        height: "44px",
                        backgroundColor: "#2bc48a",
                      }}
                      disabled={piece.isSold}
                      variant={"contained"}
                      onClick={() => {
                        window.location.href = `/painting/${piece.id}/purchase`;
                      }}
                      startIcon={<PiCrownDuotone size={25} />}
                    >
                      {`Buy for $${piece.price}`}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Divider variant="middle" style={{ width: "100%" }} />
          <p className="text-sm" align="left" pr={5}>
            {attribution}
          </p>
          <Typography
            mt={1}
            variant="overline"
            style={{ fontSize: "8pt" }}
            align="left"
            pr={5}
          >
            {attribution1}
          </Typography>

          <Grid
            item
            container
            style={{
              display: "flex",
              flexDirection: "row",
            }}
            columnGap={1}
            mt={3}
          >
            {piece.categories.map((category) => {
              return (
                <Grid item>
                  <Chip
                    onClick={() => {}}
                    sx={{ my: 0.5 }}
                    className={"chip"}
                    label={category.name}
                    variant="outlined"
                  />
                </Grid>
              );
            })}
          </Grid>
        </div>
      </div>

      {isViewerOpen && (
        <ImageViewer
          src={[selectedImage.url]}
          currentIndex={0}
          backgroundStyle={{ backgroundColor: "#000000bf" }}
          disableScroll={false}
          closeOnClickOutside={true}
          onClose={closeImageViewer}
        />
      )}

      <React.Fragment key={"drawer"}>
        <Drawer
          anchor={"right"}
          open={drawer}
          PaperProps={{
            sx: { width: "25%" },
          }}
          BackdropProps={{ style: { opacity: 0.2 } }}
          onClose={() => toggleDrawer(false)}
        >
          {renderDrawerContent(drawerContent)}
        </Drawer>
      </React.Fragment>

      <Modal
        keepMounted
        open={open}
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.05)",
        }}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>{open && <SubscriptionNote />}</Box>
      </Modal>
    </Grid>
  );
}

export default SelectedPainting;
