import { HOST_URL } from "constants";
import { makeDefaultHeaders, makeHeaders, makeHeadersFormData } from "../utils";
import axios from "axios";
import { store } from "../store";

//Get all categories
export const getAllCategories = (page, size) => {
  return axios.get(`${HOST_URL}/api/categories?page=${page}&size=${size}`, {
    makeDefaultHeaders,
  });
};

//Get art by id
export const getArtById = (id, bearerToken) => {
  let header = bearerToken ? makeHeaders(bearerToken) : makeDefaultHeaders;
  let ipAddress = store.getState().navigationReducer.ipAddress;
  return axios.get(`${HOST_URL}/api/arts/${id}?ipAddress=${ipAddress}`, {
    headers: header,
  });
};

export const getPaintingById = (id, bearerToken) => {
  let header = bearerToken ? makeHeaders(bearerToken) : makeDefaultHeaders;
  let ipAddress = store.getState().navigationReducer.ipAddress;
  return axios.get(
    `${HOST_URL}/api/arts/original/${id}?ipAddress=${ipAddress}`,
    {
      headers: header,
    },
  );
};

//Get all arts
export const getAllArts = (
  page,
  size,
  tagsFilter,
  nameFilter,
  sort,
  randomSessionId,
) => {
  let filter =
    tagsFilter && tagsFilter != "" ? "&categories.contains=" + tagsFilter : "";

  let nameFilterParsed =
    nameFilter != null && nameFilter != ""
      ? "&name.contains=" + nameFilter
      : "";

  let sortParsed =
    sort === "popular"
      ? "&sort=likes,desc"
      : sort === "random"
        ? `&order.equals=${randomSessionId}`
        : sort === "special"
          ? `&price.greaterThan=10&sort=createdDate,desc`
          : "&sort=createdDate,desc";
  return axios.get(
    `${HOST_URL}/api/arts?page=${page}&type.equals=AIART&size=${size}${filter}${nameFilterParsed}${sortParsed}`,
    {
      makeDefaultHeaders,
    },
  );
};

export const getOriginalArts = (page, size, tagsFilter, nameFilter, sort) => {
  let filter =
    tagsFilter && tagsFilter != "" ? "&categories.contains=" + tagsFilter : "";

  let nameFilterParsed =
    nameFilter != null && nameFilter != ""
      ? "&name.contains=" + nameFilter
      : "";

  return axios.get(
    `${HOST_URL}/api/arts/original?page=${page}&type.equals=PAINTING&size=${size}${filter}${nameFilterParsed}&sort=createdDate,desc`,
    {
      makeDefaultHeaders,
    },
  );
};

export const getTopCategories = () => {
  return axios.get(`${HOST_URL}/api/categories-top`, {
    makeDefaultHeaders,
  });
};

export const createPaymentIntent = (
  bearerToken,
  subscriptionPlanName,
  subscriptionPlanPricingNumberOfItems,
) => {
  let header = bearerToken ? makeHeaders(bearerToken) : makeDefaultHeaders;

  return axios.post(
    `${HOST_URL}/api/order`,
    {
      subscriptionPlanName: subscriptionPlanName,
      subscriptionPlanPricingNumberOfItems:
        subscriptionPlanPricingNumberOfItems,
    },
    { headers: header },
  );
};

export const createPurchaseIntent = (bearerToken, itemId) => {
  let header = bearerToken ? makeHeaders(bearerToken) : makeDefaultHeaders;

  return axios.post(
    `${HOST_URL}/api/purchase`,
    {
      itemId: itemId,
    },
    { headers: header },
  );
};

export const getPlan = (bearerToken, planName, numberOfItems) => {
  let header = makeHeaders(bearerToken);
  return axios.get(
    `${HOST_URL}/api/subscription-plans/${planName}?numberOfItems=${numberOfItems}`,
    { headers: header },
  );
};

export const getAllDownloads = (bearerToken, page, size) => {
  return axios.get(`${HOST_URL}/api/downloads?page=${page}&size=${size}`, {
    headers: makeHeaders(bearerToken),
  });
};

export const getAllSaves = (bearerToken, page, size) => {
  return axios.get(`${HOST_URL}/api/arts/saves?page=${page}&size=${size}`, {
    headers: makeHeaders(bearerToken),
  });
};

export const getAllLikes = (bearerToken, page, size) => {
  return axios.get(`${HOST_URL}/api/arts/likes?page=${page}&size=${size}`, {
    headers: makeHeaders(bearerToken),
  });
};

//Create like for art
export const likeDislikeArt = (id, bearerToken) => {
  let ipAddress = store.getState().navigationReducer.ipAddress;
  let header = bearerToken ? makeHeaders(bearerToken) : makeDefaultHeaders;
  return axios.post(
    `${HOST_URL}/api/arts/likes/${id}?ipAddress=${ipAddress}`,
    {},
    { headers: header },
  );
};

export const saveUnsaveArt = (id, bearerToken) => {
  let ipAddress = store.getState().navigationReducer.ipAddress;
  let header = bearerToken ? makeHeaders(bearerToken) : makeDefaultHeaders;
  return axios.post(
    `${HOST_URL}/api/arts/${id}/saves?ipAddress=${ipAddress}`,
    {},
    { headers: header },
  );
};

//count views
export const countViews = (id) => {
  return axios.get(`${HOST_URL}/api/arts/${id}/views/count`, {
    makeDefaultHeaders,
  });
};

//count comments
export const countComments = (id) => {
  return axios.get(`${HOST_URL}/api/arts/${id}/comments/count`, {
    makeDefaultHeaders,
  });
};

//count likes
export const countLikes = (id) => {
  return axios.get(`${HOST_URL}/api/arts/${id}/likes/count`, {
    makeDefaultHeaders,
  });
};

//get categories of the art
export const getCategoriesOfTheArt = (id, page, size) => {
  return axios.get(
    `${HOST_URL}/api/arts/${id}/categories?page=${page}&size=${size}`,
    { makeDefaultHeaders },
  );
};

//get art comments
export const getCommentsOfTheArt = (id, page, size) => {
  return axios.get(
    `${HOST_URL}/api/arts/${id}/comments?page=${page}&size=${size}`,
    { makeDefaultHeaders },
  );
};

//create a comment fo the art
export const addCommentOfTheArt = (id, email, fullName, comment) => {
  let ipAddress = store.getState().navigationReducer.ipAddress;

  return axios.post(
    `${HOST_URL}/api/arts/comments/${id}&ipAddress=${ipAddress}`,
    {
      email: email,
      fullName: fullName,
      comment,
    },
    { makeDefaultHeaders },
  );
};

//create a comment fo the art
export const addPainting = (data, bearerToken) => {
  let header = bearerToken
    ? makeHeadersFormData(bearerToken)
    : makeDefaultHeaders;

  for (const value of data.keys()) {
  }
  return axios.post(`${HOST_URL}/api/arts/original`, data, { headers: header });
};

export const addDeliveryInformation = (data, bearerToken) => {
  let header = bearerToken ? makeHeaders(bearerToken) : makeDefaultHeaders;

  return axios.post(`${HOST_URL}/api/delivery-information`, data, {
    headers: header,
  });
};

export const addDigitalArt = (data, bearerToken) => {
  let header = bearerToken
    ? makeHeadersFormData(bearerToken)
    : makeDefaultHeaders;

  return axios.post(`${HOST_URL}/api/arts-manual`, data, { headers: header });
};
