export const HOST_URL = "https://api.smaiart.com";
export const ASSETS_URL = "https://static.smaiart.com";
export const ORIGINAL_URL = "https://small.smaiart.com";
export const PREMIUM_URL = "https://premium.smaiart.com";
// export const HOST_URL = "http://localhost:8080";
export const ADMIN_URL = "https://api.smaiart.com/api/admin";
export const SIGN_IN = "SIGN_IN";
export const SET_AUTH = "SET_AUTH";
export const UPDATE_SUBSCRIPTION = "UPDATE_SUBSCRIPTION";
export const USER = "USER";
export const SET_IP = "SET_IP";
export const ORIGINAL_ATTRIBUTION = (
  <div className="w-full flex text-left  p-5 py-20">
    <div className=" px-10">
      <img style={{ height: 400 }} src="siyavush_mammad.jpg" />
    </div>
    <div className="md:w-3/4 text-md italic flex justify-center align-center flex-col">
      <p>
        I’m Siyavush Mammadov, born in Baku, Azerbaijan, in 1968. My journey
        began with studies at the Azerbaijan University of Architecture and
        Construction, which led me into the world of creative
        industries—illustration, digital art, photography, desktop design, and
        publishing. I’ve had the privilege of working as an art director for
        various local magazines, which deepened my connection to visual
        storytelling.
      </p>
      <p>
        Over time, my path shifted toward traditional oil painting. I work in
        various techniques and have developed my unique mash-up style. Art and
        philosophy are two sides of the same essence: art lives in form, while
        philosophy moves within the message.
      </p>
      <p className="not-italic text-end mt-10 font-semibold ">
        Siyavush Mammadov
      </p>
    </div>
  </div>
);
export const SPECIAL_ATTRIBUTION = (
  <div className="w-full flex text-left justify-end p-5 py-20">
    <div className="text-sm text-gray-500">
      <p>
        In this section, we present an exclusive collection of digital art by
        Siyavush Mammad, showcasing works spanning from the beginning of his
        career to his latest creations. These pieces are uniquely distinguished
        from those on smaiart.com by the artist’s deeper involvement in their
        creative process, resulting in truly one-of-a-kind works. Alongside
        Adobe Illustrator, Photoshop, Blender, and other professional tools,
        Siyavush integrates LoRA Stable Diffusion models—custom-trained on his
        portfolio—to push the boundaries of his digital artistry.
      </p>
    </div>
  </div>
);
