// SortableItem.js
import React from 'react';
import { Grid } from '@mui/material';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const SortableItem = ({ file, index, onRemove }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: file.name });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Grid item xs={6} sm={4} ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <img
        src={URL.createObjectURL(file)}
        alt={`preview-${index}`}
        style={{ width: '100%', height: 'auto', borderRadius: '8px', cursor: 'pointer' }}
        onClick={onRemove} // Remove image on click
      />
    </Grid>
  );
};

export default SortableItem;
