import { Elements } from "@stripe/react-stripe-js";
import getStripe from "layouts/stripe-container";
import { useEffect, useState } from "react";
import CheckoutForm from "./CheckoutForm";
import { createPaymentIntent } from "actions";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_live_51NeuPbKpKhrWrQ1nME60gE3G6xfgl3R9nAiA47JalNdJPkH1nbGZixePKE06t8tnUUEAldYqxwmNQuAH5Aaxqb3y00iFWYPkuq"
);

function PaymentForm() {
  const [clientSecret, setClientSecret] = useState(undefined);
  const [searchParams, setSearchParams] = useSearchParams();
  let [loading, setLoading] = useState(false);
  let subscriptionPlanName = searchParams.get("plan");
  let subscriptionPlanPricingNumberOfItems = searchParams.get("numberOfItems");
  let bearerToken = useSelector((state) => state.navigationReducer.bearerToken);

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    if (!clientSecret && !loading) {
      createPaymentIntent(
        bearerToken,
        subscriptionPlanName,
        subscriptionPlanPricingNumberOfItems
      )
        .then((res) => res.data)
        .then((data) => setClientSecret(data.clientSecret));
    }
    setLoading(true);
  }, []);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="StripeApp">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
    </div>
  );
}

export default PaymentForm;
